const TestErrorPage = () => import(/* webpackChunkName: "testErrorPage" */ "../../Views/Test/TestErrorPage/TestErrorPage.vue");

export default [
  {
    path: "/test-errors/",
    name: "testErrors",
    component: TestErrorPage,
    meta: {
      roles: [
        "superuser",
      ],
    },
  },
];
