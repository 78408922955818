const ErrorsPage = () => import(/* webpackChunkName: "ErrorsPage" */ "../../Views/Errors/ErrorsPage/ErrorsPage.vue");
const ErrorsNotFoundPage = () => import(/* webpackChunkName: "ErrorsNotFoundPage" */ "../../Views/Errors/ErrorsNotFoundPage/ErrorsNotFoundPage.vue");

export default [
  {
    path: "/not-found/",
    name: "notFound",
    component: ErrorsNotFoundPage,
    meta: {
      loginNoMatter: true,
    },
  },
  {
    path: "/404/",
    name: "errors404",
    component: ErrorsNotFoundPage,
    meta: {
      loginNoMatter: true,
    },
  },
  {
    path: "/40*/",
    name: "errors40*",
    component: ErrorsPage,
    meta: {
      loginNoMatter: true,
    },
    props: {
      title: "Der Server hat einen unerwarteten Fehler gemeldet",
    },
  },
  {
    path: "/50*/",
    name: "errors50*",
    component: ErrorsPage,
    meta: {
      loginNoMatter: true,
    },
    props: {
      title: "Der Server hat einen unerwarteten Fehler gemeldet",
    },
  },
  {
    // If the routing configuration '*' reports an error, replace it with '/: catchAll(. *)'
    // caught Error: Catch all routes ("*") must now be defined using a param with a custom regexp
    path: "/:catchAll(.*)", // Special attention should be paid to the bottom
    redirect: "/404/",
  }
];
