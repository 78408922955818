import {
  computed,
  toRef,
} from "vue";

import {
  get,
} from "lodash-es";

export default function OrganisationAPI(props) {
  const user = toRef(props, "user");

  const organisation = computed(() => {
    return get(user.value, "organisation_obj.name");
  });

  return {
    organisation,
  };
}
