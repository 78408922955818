import {
  ref,
} from "vue";

import Cookies from "js-cookie";

export default function CookiesAPI() {
  const isComponentVisible = ref(false);

  const saveCookies = () => {
    Cookies.set("prounix", true, { expires: 365 });
    isComponentVisible.value = false;
  };

  const checkCookies = () => {
    if (!Cookies.get("prounix")) {
      isComponentVisible.value = true;
    }
  };

  return {
    checkCookies,
    isComponentVisible,
    saveCookies,
  };
}
