import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  cloneDeep,
} from "lodash-es";

const loadingBranding = ref(true);
const branding = ref({});

export default function BrandingAPI() {
  const ELEMENT_ID = "navbar_styles";

  const {
    getHttp,
  } = AHttpAPI();

  const addCurrentStylesInHeader = styles => {
    const ELEMENT_STYLE = document.createElement("style");
    ELEMENT_STYLE.id = ELEMENT_ID;
    ELEMENT_STYLE.textContent = styles;


    const ELEMENT = document.getElementById(ELEMENT_ID);
    if (ELEMENT) {
      ELEMENT.replaceWith(ELEMENT_STYLE);
    } else {
      document.head.appendChild(ELEMENT_STYLE);
    }
  };

  const removeStylesInHeader = () => {
    const ELEMENT = document.getElementById(ELEMENT_ID);
    if (ELEMENT) {
      ELEMENT.remove();
    }
  };

  const setStylesForNavbar = () => {
    if (!branding.value ||
      !branding.value.col_primarycolor) {
      return;
    }
    const STYLES = `
      .the_navbar {
        --the_navbar_bg: ${ branding.value.col_primarycolor };
        --the_navbar_color: ${ branding.value.col_textcolor };
      }
      .the_navbar .a_btn_outline_light:not(.the_navbar__brand) {
        --a_btn_color: ${ branding.value.col_textcolor };
        --a_btn_border_color: ${ branding.value.col_secondarycolor };
        --a_btn_bg: ${ branding.value.col_secondarycolor };
      }
    `;
    addCurrentStylesInHeader(STYLES);
  };

  const loadBranding = () => {
    getHttp({
      url: "branding/",
    }).then(
      response => {
        branding.value = response;
        setStylesForNavbar();
        loadingBranding.value = false;
      }
    );
  };

  const updateBranding = brandingLocal => {
    branding.value = cloneDeep(brandingLocal);
    setStylesForNavbar();
  };


  return {
    branding,
    loadBranding,
    loadingBranding,
    removeStylesInHeader,
    updateBranding,
  };
}
