import {
  computed,
  ref,
} from "vue";

import {
  getHttp,
  setHeaderParams,
} from "aloha-vue/src/compositionAPI/AHttpAPI";

import Organisationstypen from "../const/Organisationstypen";
import {
  cloneDeep,
  get,
  isNil,
} from "lodash-es";

export const me = ref(undefined);
const warMeLoaded = ref(false);

const organisationTyp = computed(() => {
  return get(me, "value.organisation_obj.typ");
});

export const isStudierende = computed(() => {
  return organisationTyp.value === Organisationstypen.Studierende;
});

export const isUni = computed(() => {
  return organisationTyp.value === Organisationstypen.Uni;
});

export const isSuperuser = computed(() => {
  return isNil(organisationTyp.value);
});

export const isBR = computed(() => {
  return organisationTyp.value === Organisationstypen.BR;
});

export const isZfsl = computed(() => {
  return organisationTyp.value === Organisationstypen.Zfsl;
});

export const isSeminar = computed(() => {
  return organisationTyp.value === Organisationstypen.Seminar;
});

export const isSchule = computed(() => {
  return organisationTyp.value === Organisationstypen.Schule;
});

export const isMinisterium = computed(() => {
  return organisationTyp.value === Organisationstypen.Ministerium;
});

export const isAuthenticated = computed(() => {
  return !!(me.value && me.value.pk);
});

export const isMultipleUsersSelect = computed(() => {
  return isAuthenticated.value &&
    get(me.value, "multiple_users_select");
});

export const isPasswordChangeRequired = computed(() => {
  return isAuthenticated.value &&
    get(me.value, "login_pwdirty");
});

export default function UserAPI() {
  const fullName = computed(() => {
    return `${ me.value.u_vorname } ${ me.value.u_nachname }`;
  });

  const organisationName = computed(() => {
    return get(me, "value.organisation_obj.name");
  });

  return {
    fullName,
    getUser,
    isAuthenticated,
    isBR,
    isMinisterium,
    isMultipleUsersSelect,
    isPasswordChangeRequired,
    isSchule,
    isSeminar,
    isStudierende,
    isSuperuser,
    isUni,
    isZfsl,
    me,
    organisationName,
    organisationTyp,
    setMe,
  };
}

export function getUser({ isReload = false } = {}) {
  if (window.userPromise && !window.userPromiseIsFulfilled && !isReload) {
    return window.userPromise;
  }
  window.userPromise = new Promise((resolve, reject) => {
    window.userPromiseIsFulfilled = false;
    if (warMeLoaded.value && !isReload) {
      window.userPromiseIsFulfilled = true;
      return resolve(me.value);
    }
    return getHttp({
      url: "profil/",
    }).then(
      response => {
        setMe(response);
        warMeLoaded.value = true;
        return resolve(response);
      },
      error => {
        return reject(error);
      }
    );
  }).finally(() => {
    window.userPromiseIsFulfilled = true;
  });
  return window.userPromise;
}

export function setMe(meLocal) {
  me.value = cloneDeep(meLocal);
  if (meLocal && meLocal.pk) {
    setHeaderParams({
      headerParams: {
        uid: meLocal.pk,
      },
    });
  } else {
    setHeaderParams({
      headerParams: {},
    });
  }
}
