import {
  createRouter,
  createWebHistory,
} from "vue-router";

import AdministrationRoutes from "./Routes/AdministrationRoutes";
import BenutzerRollenRoutes from "./Routes/BenutzerRollenRoutes";
import DefaultRoutes from "./Routes/DefaultRoutes";
import DokumenteRoutes from "./Routes/DokumenteRoutes";
import ErrorsRoutes from "./Routes/ErrorsRoutes";
import InstanzenRoutes from "./Routes/InstanzenRoutes";
import KommunikationRoutes from "./Routes/KommunikationRoutes";
import MerkmaleRoutes from "./Routes/MerkmaleRoutes";
import OrganisationRoutes from "./Routes/OrganisationRoutes";
import PraxissemesterRoutes from "./Routes/PraxissemesterRoutes";
import RegistrationRoutes from "./Routes/RegistrationRoutes";
import StudierendeRoutes from "./Routes/StudierendeRoutes";
import TestRoutes from "./Routes/TestRoutes";
import UniInstanzenRoutes from "./Routes/UniInstanzenRoutes";
import UniversitaetRoutes from "./Routes/UniversitaetRoutes";
import {
  getUser,
  isAuthenticated,
  isMultipleUsersSelect,
  isPasswordChangeRequired,
} from "../global/compositionAPI/UserAPI";
import {
  isInstanzSelected,
  loadInstanzen,
  updateModelInstanzenWithoutRedirect,
} from "../Views/Instanzen/compositionAPI/InstanzenUniAPI";
import {
  loadStudierendeInstanzen,
} from "../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";
import {
  loadStudierendeInstanzenStatuses,
} from "../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenStatusAPI";
import {
  addNotification,
} from "aloha-vue/src/compositionAPI/ANotificationAPI";
import {
  targetRoute,
} from "../global/functions/ErrorListenerForCodeSplitting";
import {
  isRouteVisible,
} from "./RouterUtils";
import {
  get,
} from "lodash-es";


const ROUTES = [
  ...DefaultRoutes,
  ...OrganisationRoutes,
  ...InstanzenRoutes,
  ...UniversitaetRoutes,
  ...PraxissemesterRoutes,
  ...MerkmaleRoutes,
  ...DokumenteRoutes,
  ...BenutzerRollenRoutes,
  ...AdministrationRoutes,
  ...KommunikationRoutes,
  ...RegistrationRoutes,
  ...UniInstanzenRoutes,
  ...StudierendeRoutes,
  ...TestRoutes,
  ...ErrorsRoutes,
];

const ROUTER = createRouter({
  history: createWebHistory("/"),
  routes: ROUTES,
  scrollBehavior() {
    // always scroll to top
    return {
      top: 0,
    };
  },
});

const setInstanz = to => {
  const HAS_INSTANZ = get(to, "meta.instanz");
  const INSTANZ_FROM_QUERY = get(to, "query.instanz");
  if (HAS_INSTANZ && INSTANZ_FROM_QUERY) {
    updateModelInstanzenWithoutRedirect(INSTANZ_FROM_QUERY);
  }
};

ROUTER.beforeEach(async to => {
  targetRoute.value = {
    path: to.path,
    query: to.query,
  };
  if (to.name === "wartungPage") {
    return true;
  }
  try {
    await getUser();
  } catch (e) {

  }
  if (to.name === "logoutPage") {
    return true;
  }

  if (isPasswordChangeRequired.value) {
    if (to.name === "passwordChangeRequired") {
      return true;
    }
    return { name: "passwordChangeRequired" };
  }
  if (isMultipleUsersSelect.value) {
    if (to.name === "kontextauswahlPage") {
      return true;
    }
    return { name: "kontextauswahlPage" };
  }

  await loadInstanzen();
  await loadStudierendeInstanzen();
  await loadStudierendeInstanzenStatuses({ isUpdate: false });
  setInstanz(to);

  const {
    canAccess,
    toLogin,
    toStart,
    toNotFound,
  } = await canUserAccess(to);
  if (toStart) {
    return { name: "start" };
  }
  if (toNotFound) {
    return { name: "notFound" };
  }
  if (canAccess) {
    if (to.name === "login") {
      return { name: "start" };
    }
    return true;
  } else if (canAccess === false) {
    return { name: "start" };
  }
  if (toLogin) {
    if (to.name === "login") {
      return true;
    }
    return { name: "login" };
  }
  return true;
});

function canUserAccess(to) {
  const PERMISSIONS = get(to, "meta.permissions") || [];
  const IS_NOT_LOGGED = get(to, "meta.isNotLogged");
  const LOGIN_NO_MATER = get(to, "meta.loginNoMatter");
  const HAS_INSTANZ = get(to, "meta.instanz");

  if (LOGIN_NO_MATER) {
    return {};
  }
  if (isAuthenticated.value) {
    if (IS_NOT_LOGGED ||
      to.name === "kontextauswahlPage") {
      return { toStart: true };
    }
    if (HAS_INSTANZ && !isInstanzSelected.value) {
      addNotification({
        text: "Sie haben keine Instanz ausgewählt",
        type: "warning",
      });
      return {
        toNotFound: true,
      };
    }
    if (!PERMISSIONS.length) {
      return { canAccess: true };
    }
    if (isRouteVisible({ to })) {
      return { canAccess: true };
    }
    return { canAccess: false };
  }
  if (IS_NOT_LOGGED) {
    return {};
  }
  return { toLogin: true };
}

export default ROUTER;
