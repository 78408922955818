import {
  computed,
} from "vue";

import AButton from "aloha-vue/src/AButton/AButton";
import AIcon from "aloha-vue/src/AIcon/AIcon";
import ALink from "aloha-vue/src/ALink/ALink";
import AMenu from "aloha-vue/src/AMenu/AMenu";
import ASelect from "aloha-vue/src/ui/ASelect/ASelect";
import TheMenuInstanzenSelectItem from "./TheMenuInstanzenSelectItem/TheMenuInstanzenSelectItem.vue";

import AMenuUpdateAPI from "./compositionAPI/AMenuUpdateAPI";
import BrandingAPI from "../../global/compositionAPI/BrandingAPI";
import DataAPI from "./compositionAPI/DataAPI";
import UserAPI from "../../global/compositionAPI/UserAPI";
import StudierendeInstanzenAPI from "../../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";
import TitleStudierendeAPI from "./compositionAPI/TitleStudierendeAPI";


// @vue/component
export default {
  name: "TheMenu",
  components: {
    AButton,
    AIcon,
    ALink,
    AMenu,
    ASelect,
    TheMenuInstanzenSelectItem,
  },
  setup() {
    const {
      aMenuRef,
    } = AMenuUpdateAPI();

    const {
      organisationName,
      isStudierende,
    } = UserAPI();

    const {
      branding,
      loadBranding,
      loadingBranding,
    } = BrandingAPI();

    const {
      dataFiltered,
      dataInstanzen,
      isLoadingInstanzen,
      modelInstanzen,
      updateModelInstanzen,
    } = DataAPI();

    const {
      dataStudierendeInstanzen,
      isLoadingStudierendeInstanzen,
      modelStudierendeInstanzen,
      updateModelStudierendeInstanzen,
    } = StudierendeInstanzenAPI();

    const {
      showMenuLinkInfo,
    } = TitleStudierendeAPI();

    const altForLogo = computed(() => {
      return `Logo ${ organisationName.value }`;
    });

    loadBranding();

    return {
      altForLogo,
      aMenuRef,
      branding,
      dataFiltered,
      dataInstanzen,
      dataStudierendeInstanzen,
      isLoadingInstanzen,
      isLoadingStudierendeInstanzen,
      isStudierende,
      loadingBranding,
      modelInstanzen,
      modelStudierendeInstanzen,
      showMenuLinkInfo,
      updateModelInstanzen,
      updateModelStudierendeInstanzen,
    };
  },
};
