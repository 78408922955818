const INSTANZEN_LIST = () => import(/* webpackChunkName: "instanzenList" */ "../../Views/Instanzen/InstanzenList/InstanzenList.vue");
const INSTANZEN_DETAILS = () => import(/* webpackChunkName: "instanzenDetails" */ "../../Views/Instanzen/InstanzenDetails/InstanzenDetails.vue");

export default [
  {
    path: "/instanzen/",
    name: "instanzenList",
    component: INSTANZEN_LIST,
    meta: {
      permissions: [
        "backend.instance.list",
      ],
    },
  },
  {
    path: "/instanzen/:id/",
    name: "instanzenDetails",
    component: INSTANZEN_DETAILS,
    meta: {
      permissions: [
        "backend.instance.list",
      ],
    },
  },
];
