import {
  onBeforeUnmount,
} from "vue";

import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import AIcon from "aloha-vue/src/AIcon/AIcon";
import ALink from "aloha-vue/src/ALink/ALink";
import TheNavbarMessagesItem from "./TheNavbarMessagesItem/TheNavbarMessagesItem.vue";

import DataAPI from "./compositionAPI/DataAPI";

// @vue/component
export default {
  name: "TheNavbarMessages",
  components: {
    ADropdown,
    AIcon,
    ALink,
    TheNavbarMessagesItem,
  },
  setup() {
    const {
      destroyEventBus,
      hasPermissionMessagesView,
      initEventBus,
      loading,
      loadMessages,
      me,
      messagesCount,
      messagesPreview,
    } = DataAPI();

    loadMessages();
    initEventBus();

    onBeforeUnmount(() => {
      destroyEventBus();
    });

    return {
      hasPermissionMessagesView,
      loading,
      me,
      messagesCount,
      messagesPreview,
    };
  },
};
