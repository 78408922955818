const HistorieList = () => import(/* webpackChunkName: "historieList" */ "../../Views/BenutzerRollen/Historie/HistorieList/HistorieList.vue");
const NutzerDetails = () => import(/* webpackChunkName: "nutzerDetails" */ "../../Views/BenutzerRollen/Nutzer/NutzerDetails/NutzerDetails.vue");
const NutzerList = () => import(/* webpackChunkName: "nutzerList" */ "../../Views/BenutzerRollen/Nutzer/NutzerList/NutzerList.vue");
const RolleDetails = () => import(/* webpackChunkName: "rolleDetails" */ "../../Views/BenutzerRollen/Rolle/RolleDetails/RolleDetails.vue");
const RolleList = () => import(/* webpackChunkName: "rolleList" */ "../../Views/BenutzerRollen/Rolle/RolleList/RolleList.vue");
const SystemRolleDetails = () => import(/* webpackChunkName: "systemRolleDetails" */ "../../Views/BenutzerRollen/SystemRolle/SystemRolleDetails/SystemRolleDetails.vue");
const SystemRolleList = () => import(/* webpackChunkName: "systemRolleList" */ "../../Views/BenutzerRollen/SystemRolle/SystemRolleList/SystemRolleList.vue");

export default [
  {
    path: "/benutzer/",
    name: "nutzerList",
    component: NutzerList,
    meta: {
      permissions: [
        "backend.user.view",
      ],
    },
  },
  {
    path: "/benutzer/:id/",
    name: "nutzerDetails",
    component: NutzerDetails,
    meta: {
      permissions: [
        "backend.user.view",
      ],
    },
  },
  {
    path: "/rollen/",
    name: "rolleList",
    component: RolleList,
    meta: {
      permissions: [
        "backend.role.view",
      ],
    },
  },
  {
    path: "/rollen/:id/",
    name: "rolleDetails",
    component: RolleDetails,
    meta: {
      permissions: [
        "backend.role.view",
      ],
    },
  },
  {
    path: "/historie/",
    name: "historieList",
    component: HistorieList,
    meta: {
      permissions: [
        "backend.user.view_history",
      ],
    },
  },
  {
    path: "/sysrole/",
    name: "sysroleList",
    component: SystemRolleList,
    meta: {
      permissions: [
        "backend.sysrole.view",
      ],
    },
  },
  {
    path: "/sysrole/:id/",
    name: "sysroleDetails",
    component: SystemRolleDetails,
    meta: {
      permissions: [
        "backend.sysrole.view",
      ],
    },
  },
];
