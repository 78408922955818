import {
  computed,
} from "vue";

import {
  isStudierende,
} from "../../../global/compositionAPI/UserAPI";
import {
  studierendeInstanzenStatuses,
} from "../../../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenStatusAPI";

import date from "aloha-vue/src/filters/date";
import StudierendeIconsStatusMapping from "../../../Views/ViewsStudierende/const/StudierendeIconsStatusMapping";
import StudierendeStatusesMapping from "../../../Views/ViewsStudierende/const/StudierendeStatusesMapping";
import {
  currentStudierendeInstanz,
} from "../../../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";
import {
  get, isFunction,
} from "lodash-es";

const persoenlicheDaten = computed(() => {
  return getRouteData("persoenliche_daten");
});

const studiendaten = computed(() => {
  return getRouteData("studiendaten");
});

const besuchteSchulen = computed(() => {
  return getRouteData("besuchte_schulen");
});

const profildaten = computed(() => {
  return getRouteData("profildaten");
});

const ortspunkt = computed(() => {
  return getRouteData("ortspunkt");
});

const merkmale = computed(() => {
  return getRouteData("merkmale");
});

const favoriten = computed(() => {
  return getRouteData("favoriten");
});

const wunschliste = computed(() => {
  return getRouteData("wunschliste");
});

const finalisieren = computed(() => {
  return getRouteData("finalisieren");
});

const anmeldung = computed(() => {
  return getRouteData("anmeldung");
});

const zuweisung = computed(() => {
  return getRouteData("zuweisung", currentStudierendeInstanz.value);
});

const praktikum = computed(() => {
  return getRouteData("praktikum");
});


export const studierendeData = computed(() => {
  if (!isStudierende.value) {
    return [];
  }
  return [
    persoenlicheDaten.value,
    studiendaten.value,
    besuchteSchulen.value,
    profildaten.value,
    ortspunkt.value,
    merkmale.value,
    favoriten.value,
    wunschliste.value,
    finalisieren.value,
    anmeldung.value,
    zuweisung.value,
    praktikum.value,
  ];
});

function getTitleHtml(menuItemId) {
  let titleHtml = StudierendeStatusesMapping[menuItemId].titleHtml;
  const STATUS = get(studierendeInstanzenStatuses.value, `${ menuItemId }.status`);
  const FRIST = get(studierendeInstanzenStatuses.value, `${ menuItemId }.frist`);
  if (FRIST) {
    titleHtml += `<p class="a_mt_3"><strong>Bearbeitungszeitraum:</strong> ${ date(FRIST.idl_gueltig_ab) } bis ${ date(FRIST.idl_gueltig_bis) }</p>`;
  }
  if (STATUS) {
    if (StudierendeStatusesMapping[menuItemId].titleHtmlAufgabe) {
      if (StudierendeStatusesMapping[menuItemId].titleHtmlAufgabe[STATUS]) {
        titleHtml += `<p class="a_mt_3"><strong>${ StudierendeStatusesMapping[menuItemId].titleHtmlAufgabe[STATUS] }</strong></p>`;
      }
    } else {
      titleHtml += `<p class="a_mt_3"><strong>${ StudierendeIconsStatusMapping[STATUS].description }</strong></p>`;
    }
  }
  return titleHtml;
}


function getRouteData(menuItemId, currentStudierendeInstanz) {
  const label = isFunction(StudierendeStatusesMapping[menuItemId].label) ?
    StudierendeStatusesMapping[menuItemId].label({ currentStudierendeInstanz }) :
    StudierendeStatusesMapping[menuItemId].label;

  const DATA = {
    id: menuItemId,
    label,
    to: {
      name: StudierendeStatusesMapping[menuItemId].route,
    },
    titleHtml: getTitleHtml(menuItemId),
    icon: "ThreeDots",
    iconClass: "a_icon_round a_bg_gray_700",
    titleHtmlMaxWidth: 350,
    titleHtmlClass: "a_text_center",
    slot: "studierende",
  };
  const STATUS = get(studierendeInstanzenStatuses.value, `${ menuItemId }.status`);
  if (STATUS) {
    if (StudierendeIconsStatusMapping[STATUS]) {
      DATA.icon = StudierendeIconsStatusMapping[STATUS].icon;
      DATA.iconClass = `a_icon_round ${ StudierendeIconsStatusMapping[STATUS].iconClass }`;
    }
    if (STATUS === "DISABLED") {
      DATA.disabled = true;
    }
  }

  return DATA;
}

