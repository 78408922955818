import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import ALink from "aloha-vue/src/ALink/ALink";
import KontextwechselModal from "../../../Views/Registration/KontextauswahlPage/KontextwechselModal/KontextwechselModal.vue";

import ActionsAPI from "./compositionAPI/ActionsAPI";
import UserAPI from "../../../global/compositionAPI/UserAPI";

// @vue/component
export default {
  name: "TheNavbarMessages",
  components: {
    ADropdown,
    ALink,
    KontextwechselModal,
  },
  setup() {
    const {
      fullName,
      me,
      isAuthenticated,
      organisationName,
    } = UserAPI();

    const {
      actions,
      closeKontextwechselModal,
      isKontextwechselModalVisible,
    } = ActionsAPI();

    return {
      fullName,
      isAuthenticated,
      actions,
      closeKontextwechselModal,
      isKontextwechselModalVisible,
      me,
      organisationName,
    };
  },
};
