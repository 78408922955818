// @vue/component
export default {
  name: "TheScrollTop",
  data() {
    return {
      scrollTimer: 0,
      scrollY: 0,
    };
  },
  computed: {
    isScrollBtnVisible() {
      return this.scrollY > 200;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
  },
  methods: {
    scrollWindow() {
      if (this.scrollTimer) {
        return;
      }
      this.scrollTimer = setTimeout(() => {
        this.scrollY = window.scrollY;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = 0;
      }, 100);
    },

    moveUp() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      // const DURATION = Math.min(400, Math.max(100, parseInt($("html").scrollTop() / 3)));
      // $("html, body").animate({ scrollTop: 0 }, DURATION);
    },
  },
};
