export default {
  COMPLETE: {
    iconClass: "a_bg_success",
    icon: "Check",
    description: "Diese Aufgabe wurde bereits erfüllt.",
  },
  OPTIONAL: {
    iconClass: "a_bg_warning",
    icon: "Check",
    description: "Die Erfüllung dieser Aufgabe ist freiwillig.",
  },
  INCOMPLETE: {
    iconClass: "a_bg_danger",
    icon: "X",
    description: "Diese Aufgabe ist noch nicht abgeschlossen.",
  },
  DISABLED: {
    iconClass: "a_bg_gray_700",
    icon: "Lock",
    description: "Diese Aufgabe kann (noch) nicht bearbeitet werden.",
  },
  PENDING: {
    iconClass: "a_bg_gray_700",
    icon: "ThreeDots",
    description: "Diese Aufgabe wird momentan bearbeitet.",
  },
};
