import {
  computed,
  toRef,
} from "vue";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

export default function RestAPI(props) {
  const newsData = toRef(props, "newsData");

  const {
    filterDate,
  } = AFiltersAPI();

  const newsDate = computed(() => {
    return filterDate(newsData.value.ne_gueltig_ab);
  });

  const newsNutzerObj = computed(() => {
    return newsData.value.nutzer_obj || {};
  });

  const newsNutzerObjOrganisationObj = computed(() => {
    return newsNutzerObj.value.organisation_obj || {};
  });

  return {
    newsDate,
    newsNutzerObj,
    newsNutzerObjOrganisationObj,
  };
}
