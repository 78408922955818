import {
  computed,
} from "vue";

import KontextwechselModalAPI from "../../../../Views/Registration/KontextauswahlPage/KontextwechselModal/compositionAPI/KontextwechselModalAPI";

export default function ActionsAPI() {
  const {
    closeKontextwechselModal,
    isBtnKontextwechselVisible,
    isKontextwechselModalVisible,
    openKontextwechselModal,
  } = KontextwechselModalAPI();

  const actions = computed(() => {
    return [
      {
        type: "button",
        text: "Wechsel der Organisation",
        iconLeft: "Transfer",
        callback: openKontextwechselModal,
        isHidden: !isBtnKontextwechselVisible.value,
      },
      {
        type: "divider",
      },
      {
        type: "link",
        text: "Abmelden",
        iconLeft: "Off",
        to: {
          name: "logoutPage",
        },
      },
    ];
  });

  return {
    actions,
    closeKontextwechselModal,
    isKontextwechselModalVisible,
  };
}
