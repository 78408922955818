import TheBreadcrumbItem from "./TheBreadcrumbItem/TheBreadcrumbItem.vue";

import MenuAPI from "./compositionAPI/MenuAPI";
import TheBreadcrumbAPI from "./compositionAPI/TheBreadcrumbAPI";

// @vue/component
export default {
  name: "TheBreadcrumb",
  components: {
    TheBreadcrumbItem,
  },
  setup() {
    const {
      breadcrumbItems,
    } = TheBreadcrumbAPI();

    const {
      openMenuPanel,
    } = MenuAPI({
      breadcrumbItems,
    });

    return {
      breadcrumbItems,
      openMenuPanel,
    };
  },
};
