export default [
  {
    id: "verwaltung",
    label: "Startseite",
    to: {
      name: "verwaltung",
    },
    icon: "Home",
  },
  {
    id: "universitaet",
    label: "Universität",
    icon: "Education",
    children: [
      {
        id: "studiengangVerwalten",
        label: "Studiengänge verwalten",
        to: {
          name: "studiengangVerwaltenList",
        },
      },
      {
        id: "studienrichtung",
        label: "Studienrichtungen verwalten",
        to: {
          name: "studienrichtungVerwaltenList",
        },
      },
      {
        id: "universitaetsschulfach",
        label: "Universitätsschulfächer verwalten",
        to: {
          name: "universitaetsschulfachVerwaltenList",
        },
      },
    ],
  },
  {
    id: "organisationen",
    label: "Organisationen",
    icon: "Diagram3Fill",
    children: [
      {
        id: "organisationen-preview",
        label: "Übersicht anzeigen",
        to: {
          name: "organisationenPreview",
        },
      },
      {
        id: "ownZfslDetails",
        label: "Zfsl anzeigen",
        to: {
          name: "ownZfslDetails",
        },
      },
      {
        id: "zfsL",
        label: "ZfsL verwalten",
        to: {
          name: "zfslList",
        },
      },
      {
        id: "seminar",
        label: "Seminare verwalten",
        to: {
          name: "seminarList",
        },
      },
      {
        id: "seminarAnsehen",
        label: "Seminare ansehen",
        to: {
          name: "seminarAnsehenList",
        },
      },
      {
        id: "ownSeminar",
        label: "Seminar verwalten",
        to: {
          name: "ownSeminarDetails",
        },
      },
      {
        id: "schule",
        label: "Schulen verwalten",
        to: {
          name: "schuleList",
        },
      },
      {
        id: "schuleAnsehen",
        label: "Schulen ansehen",
        to: {
          name: "schuleAnsehenList",
        },
      },
      {
        id: "ownSchule",
        label: "Schuldaten verwalten",
        to: {
          name: "ownSchuleDetails",
        },
      },
      {
        id: "ausleihen",
        label: "Ausleihen verwalten",
        to: {
          name: "ausleihenList",
        },
      },
      {
        id: "region",
        label: "Regionen verwalten",
        to: {
          name: "regionList",
        },
      },
      {
        id: "schulSeminarZuordnungen",
        label: "Schul-Seminar-Zuordnungen ansehen",
        to: {
          name: "schulseminarList",
        },
      },
      {
        id: "bezirksregierung",
        label: "Bezirksregierungen verwalten",
        to: {
          name: "bezirksregierungList",
        },
      },
      {
        id: "universitaet",
        label: "Universitäten verwalten",
        to: {
          name: "universitaetList",
        },
      },
    ]
  },
  {
    id: "praxissemester",
    label: "Praxissemester",
    icon: "BarChartLineFill",
    children: [
      {
        id: "schulenAnsehenList",
        label: "Schulen ansehen",
        to: {
          name: "schulenAnsehenList",
        },
      },
      {
        id: "studierendeList",
        label: "Studierende verwalten",
        to: {
          name: "studierendeList",
        },
      },
      {
        id: "schulenFaecher",
        label: "Instanzdaten der Universitäten",
        to: {
          name: "schulenFaecherList",
        },
      },
      {
        id: "schulenStudierende",
        label: "Studierende an Schulen",
        to: {
          name: "schulenStudierendeList",
        },
      },
      {
        id: "seminareStudierende",
        label: "Studierende an Seminaren",
        to: {
          name: "seminareStudierendeList",
        },
      },
      {
        id: "schulseminarStudierende",
        label: "Studierende je Schul-Seminar-Zuordnung",
        to: {
          name: "schulseminarStudierendeList",
        },
      },
      {
        id: "restplaetze",
        label: "Restplatzvergabe",
        to: {
          name: "restplaetzeList",
        },
      },
    ],
  },
  {
    id: "download",
    label: "Download CSV-Exporte",
    icon: "Save",
    children: [
      {
        id: "downloads",
        label: "Downloads ansehen",
        to: {
          name: "downloadsList",
        },
      },
    ],
  },
  {
    id: "merkmale",
    label: "Merkmale",
    icon: "Tags",
    children: [
      {
        id: "schulmerkmale",
        label: "Schulmerkmale verwalten",
        to: {
          name: "schulmerkmaleDetails",
        },
      },
      {
        id: "zuweisungsmerkmale",
        label: "Zuweisungsmerkmale verwalten",
        to: {
          name: "zuweisungsmerkmaleDetails",
        },
      },
      {
        id: "ausschlusssmerkmale",
        label: "Ausschlussmerkmale verwalten",
        to: {
          name: "ausschlusssmerkmaleDetails",
        },
      },
    ],
  },
  {
    id: "dokumente",
    label: "Dokumente",
    icon: "FolderOpen",
    children: [
      {
        id: "statischeDokumente",
        label: "Statische Dokumente verwalten",
        to: {
          name: "statischeDokumenteList",
        },
      },
      {
        id: "dynamischeDokumente",
        label: "Templates verwalten",
        to: {
          name: "dynamischeDokumenteList",
        },
      },
    ],
  },
  {
    id: "user",
    label: "Benutzer*innen und Rollen",
    icon: "User",
    children: [
      {
        id: "nutzer",
        label: "Benutzer*innen verwalten",
        to: {
          name: "nutzerList",
        },
      },
      {
        id: "rolle",
        label: "Rollen verwalten",
        to: {
          name: "rolleList",
        },
      },
      {
        id: "historie",
        label: "Historie anzeigen",
        to: {
          name: "historieList",
        },
      },
      {
        id: "sysrole",
        label: "Rollen verwalten",
        to: {
          name: "sysroleList",
        },
      },
    ],
  },
  {
    id: "administration",
    label: "Administration",
    icon: "Cog",
    children: [
      {
        id: "adminFristEFZErinnerung",
        label: "Frist für EFZ-Erinnerung",
        to: {
          name: "adminFristEFZErinnerung",
        },
      },
      {
        id: "adminMeldung",
        label: "Meldungen verwalten",
        to: {
          name: "adminMeldungList",
        },
      },
      {
        id: "adminOrganisation",
        label: "Organisationsdaten bearbeiten",
        to: {
          name: "adminOrganisation",
        },
      },
      {
        id: "adminBranding",
        label: "Branding festlegen",
        to: {
          name: "adminBranding",
        },
      },
      {
        id: "adminHilfethemen",
        label: "Hilfethemen verwalten",
        to: {
          name: "hilfethemenList",
        },
      },
      {
        id: "ausschlussgrund",
        label: "Ausschlussgründe verwalten",
        to: {
          name: "ausschlussgrundList",
        },
      },
      {
        id: "schulfaecher",
        label: "Schulfächer ansehen",
        to: {
          name: "schulfaecherList",
        },
      },
      {
        id: "schulformen",
        label: "Schulformen ansehen",
        to: {
          name: "schulformenList",
        },
      },
      {
        id: "schultraeger",
        label: "Schulträger ansehen",
        to: {
          name: "schultraegerList",
        },
      },
      {
        id: "frist",
        label: "Fristen verwalten",
        to: {
          name: "fristList",
        },
      },
      {
        id: "seminartyp",
        label: "Seminartypen verwalten",
        to: {
          name: "seminartypList",
        },
      },
      {
        id: "logEintraege",
        label: "Log-Einträge anzeigen",
        to: {
          name: "logEintraege",
        },
      },
      {
        id: "faqDetailsAdmin",
        label: "FAQ verwalten",
        to: {
          name: "faqDetailsAdmin",
        },
      },
    ],
  },
  {
    id: "kommunikation",
    label: "Kommunikation",
    icon: "ChatDotsFill",
    children: [
      {
        id: "faqDetails",
        label: "Online Hilfe (FAQ)",
        to: {
          name: "faqDetails",
        },
      },
      {
        id: "newsVerwalten",
        label: "News verwalten",
        to: {
          name: "newsVerwaltenList",
        },
      },
      {
        id: "newsView",
        label: "News anzeigen",
        to: {
          name: "newsView",
        },
      },
      {
        id: "nachrichtentemplates",
        label: "Nachrichtentemplates verwalten",
        to: {
          name: "nachrichtentemplatesList",
        },
      },
      {
        id: "nachrichtenEingang",
        label: "Nachrichten Eingang",
        to: {
          name: "nachrichtenEingang",
        },
      },
      {
			  id: "nachrichtenGesendet",
			  label: "Nachrichten Gesendet",
			  to: {
			    name: "nachrichtenGesendet",
			  },
      },
    ],
  },
  {
    id: "info",
    label: "Weitere Informationen",
    icon: "InfoSign",
    children: [
      {
        id: "infoEreignisseList",
        label: "Ereignis-Historie anzeigen",
        to: {
          name: "infoEreignisseList",
        },
      },
    ],
  },
];
