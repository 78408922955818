import {
  computed,
  toRef,
} from "vue";

import InstanzenUniAPI from "../../../Views/Instanzen/compositionAPI/InstanzenUniAPI";
import {
  currentStudierendeInstanz,
} from "../../../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "TheBreadcrumbInstanzItem",
  props: {
    status: {
      type: Object,
      required: true,
    },
    statusIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const status = toRef(props, "status");
    const statusIndex = toRef(props, "statusIndex");

    const {
      currentInstanz,
    } = InstanzenUniAPI();

    const instanzActiveStatusPk = computed(() => {
      return get(currentStudierendeInstanz.value, "status_obj.pk") ||
        get(currentInstanz.value, "status_obj.pk");
    });

    const isStatusActive = computed(() => {
      return instanzActiveStatusPk.value === status.value.pk;
    });

    const title = computed(() => {
      return `${ statusIndex.value + 1 } - ${ status.value.ist_name }`;
    });

    return {
      isStatusActive,
      title,
    };
  },
};
