import {
  computed,
  ref,
  watch,
} from "vue";
import {
  useRoute,
} from "vue-router";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import InstanzenUniAPI from "../../../Views/Instanzen/compositionAPI/InstanzenUniAPI";
import {
  modelStudierendeInstanzen,
} from "../../../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";

export default function DataAPI() {
  const $route = useRoute();
  const statuses = ref([]);

  const {
    getHttp,
  } = AHttpAPI();

  const {
    modelInstanzen,
  } = InstanzenUniAPI();

  const isComponentVisible = computed(() => {
    return modelStudierendeInstanzen.value ||
      (modelInstanzen.value && $route.meta.instanz);
  });

  const hasStatuses = computed(() => {
    return statuses.value.length > 0;
  });

  const loadStatuses = () => {
    if (!isComponentVisible.value) {
      return;
    }
    getHttp({
      url: "instanzstatus/",
      apiSaveId: "instanzstatus",
    }).then(
      response => {
        statuses.value = response;
      }
    );
  };

  watch(isComponentVisible, () => {
    loadStatuses();
  });

  return {
    hasStatuses,
    isComponentVisible,
    loadStatuses,
    statuses,
  };
}
