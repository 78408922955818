import PuxTaskLogProgress from "./PuxTaskLogProgress/PuxTaskLogProgress.vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";

import {
  get,
  isString,
  isFunction,
} from "lodash-es";

// @vue/component
export default {
  name: "PuxTaskLog",
  components: {
    PuxTaskLogProgress,
  },
  props: {
    taskId: {
      type: String,
      default: undefined,
    },
    onError: {
      type: Function,
      default: () => ({}),
    },
    onFailure: {
      type: Function,
      default: undefined,
    },
    onFinish: {
      type: Function,
      default: undefined,
    },
    onProgress: {
      type: Function,
      default: () => ({}),
    },
    onSuccess: {
      type: Function,
      default: undefined,
    },
    blockDefaultNotifications: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      getHttp,
    } = AHttpAPI();

    const {
      addNotification,
    } = ANotificationAPI();

    return {
      addNotification,
      getHttp,
    };
  },
  data() {
    return {
      timeout: undefined,
      status: {
        loading: true,
      },
      taskLog: {
        data: {
          msg: "Ausführung wird vorbereitet",
        },
      },
      updateTime: 1000,
    };
  },
  computed: {
    isSuccess() {
      return this.taskLog.status === "SUCCESS" || this.taskLog.status === "PARTIAL_SUCCESS";
    },

    isFailure() {
      return this.taskLog.status === "FAILURE";
    },

    isInProgress() {
      return !(this.isSuccess || this.isFailure);
    },

    progressLocal() {
      if (this.isSuccess) {
        return 100;
      }
      const TASK_LOG_CURRENT = get(this.taskLog, "data.current") || 0;
      const TASK_LOG_TOTAL = get(this.taskLog, "data.total") || 0;
      if (TASK_LOG_TOTAL === 0) {
        return 0;
      }
      return TASK_LOG_CURRENT / TASK_LOG_TOTAL * 100;
    },
  },
  created() {
    this.timeout = setTimeout(this.updateTask, this.updateTime);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
  methods: {
    updateTask() {
      if (!this.taskId) {
        this.timeout = setTimeout(this.updateTask, this.updateTime);
        return;
      }
      this.getHttp({
        url: `tasks/${ this.taskId }/`,
      }).then(
        taskLog => {
          this.taskLog = taskLog;
          if (this.isInProgress) {
            this.onProgress({ tasklog: taskLog, result: taskLog.data });
            this.timeout = setTimeout(this.updateTask, this.updateTime);
          } else {
            clearTimeout(this.timeout);
            let cbHandle; // ???
            if (this.onSuccess && this.isSuccess) {
              if (taskLog.status === "SUCCESS" && !this.blockDefaultNotifications) {
                this.addNotification({ text: "Aufgabe erfolgreich abgeschlossen." });
              } else if (taskLog.status === "PARTIAL_SUCCESS" && !this.blockDefaultNotifications) {
                this.addNotification({ type: "warning", text: "Aufgabe teilweise erfolgreich abgeschlossen." });
              }
              if (this.onSuccess) {
                cbHandle = this.onSuccess({ tasklog: taskLog, result: taskLog.data }); // ???
              }
            } else if (this.onFailure && this.isFailure) {
              if (!this.blockDefaultNotifications) {
                let errorMsg;
                if (isString(taskLog.data)) {
                  errorMsg = taskLog.data;
                } else if (taskLog.data && taskLog.data.kommentar) {
                  errorMsg = "Bitte erfassen Sie einen Kommentar.";
                }
                if (errorMsg) {
                  errorMsg = `<div><strong>${ errorMsg }</strong>`;
                } else {
                  errorMsg = "";
                }
                this.addNotification({
                  type: "error",
                  text: `${ errorMsg } Fehler beim Abschließen der Aufgabe`,
                });
              }
              if (this.onFailure) {
                cbHandle = this.onFailure({ tasklog: taskLog, result: taskLog.data });
              }
            }
            if (this.onFinish) {
              if (cbHandle && isFunction(cbHandle.then)) {
                cbHandle.then(() => this.onFinish({ result: taskLog.data }));
              } else {
                this.onFinish({ result: taskLog.data });
              }
            }
          }
        },
        error => {
          this.onError(error.data);
        },
      );
    },
  },
};
