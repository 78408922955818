import AAlert from "aloha-vue/src/AAlert/AAlert";
import ACloak from "aloha-vue/src/ACloak/ACloak";
import AModal from "aloha-vue/src/AModal/AModal";
import KontextwechselModalUser from "./KontextwechselModalUser/KontextwechselModalUser.vue";

import DataFormAPI from "./compositionAPI/DataFormAPI";
import SelectAPI from "./compositionAPI/SelectAPI";

// @vue/component
export default {
  name: "KontextwechselModal",
  components: {
    AAlert,
    ACloak,
    AModal,
    KontextwechselModalUser,
  },
  props: {
    selectorCloseIds: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
  },
  emits: [
    "close",
  ],
  setup(props, context) {
    const {
      loadingUsers,
      loadUsers,
      users,
    } = DataFormAPI();

    const {
      closeLocal,
      errors,
      saving,
      selectUser,
    } = SelectAPI(props, context);

    loadUsers();

    return {
      closeLocal,
      errors,
      loadingUsers,
      saving,
      selectUser,
      users,
    };
  },
};
