import {
  computed,
  toRef,
} from "vue";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

export default function RestAPI(props) {
  const message = toRef(props, "message");

  const {
    filterDate,
  } = AFiltersAPI();

  const messageDate = computed(() => {
    return filterDate(message.value.crdate);
  });

  const absenderObj = computed(() => {
    return message.value.absender_obj;
  });

  return {
    absenderObj,
    messageDate,
  };
}
