import {
  ref,
  toRef,
} from "vue";
import {
  useRouter,
} from "vue-router";

import AHttpAPI, {
  clearAllApiSaved,
} from "aloha-vue/src/compositionAPI/AHttpAPI";
import DynamischeTexteAPI from "../../../../../global/compositionAPI/DynamischeTexteAPI";
import HilfethemenAPI from "../../../../../global/compositionAPI/HilfethemenAPI";
import InstanzenUniAPI from "../../../../Instanzen/compositionAPI/InstanzenUniAPI";
import StudierendeInstanzenAPI from "../../../../ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";
import UserAPI from "../../../../../global/compositionAPI/UserAPI";
import {
  removeStudierendeInstanzenStatuses,
} from "../../../../ViewsStudierende/compositionAPi/StudierendeInstanzenStatusAPI";

import Cookies from "js-cookie";
import EventBus from "../../../../../global/functions/EventBus";
import {
  forEach,
  startsWith,
} from "lodash-es";

export default function LoginAPI(props, {
  model = ref({}),
}) {
  const isAdmin = toRef(props, "isAdmin");

  const isLoginLoaded = ref(false);
  const errors = ref(undefined);

  const $router = useRouter();

  const {
    postHttp,
  } = AHttpAPI();

  const {
    getUser,
  } = UserAPI();

  const {
    removeInstanzen,
  } = InstanzenUniAPI();

  const {
    removeStudierendeInstanzen,
  } = StudierendeInstanzenAPI();

  const {
    loadHilfethemen,
  } = HilfethemenAPI();

  const {
    loadDynamischeTexte,
  } = DynamischeTexteAPI();

  const removeCookies = () => {
    const ALL_COOKIES = Cookies.get();
    forEach(ALL_COOKIES, (_, key) => {
      if (startsWith(key, "table_")) {
        Cookies.remove(key);
      }
    });
    Cookies.remove("pux_semester");
  };

  const onAfterLoginFunctions = ({ isKontextwechsel } = {}) => {
    clearAllApiSaved();
    removeInstanzen();
    removeStudierendeInstanzen();
    removeStudierendeInstanzenStatuses();
    loadHilfethemen();
    loadDynamischeTexte();
    removeCookies();
    if (isKontextwechsel) {
      EventBus.$emit("updateNavbarNews");
      EventBus.$emit("updateNavbarMessages");
    }
  };

  const onAfterLogin = ({ isKontextwechsel } = {}) => {
    getUser({ isReload: true }).then(
      () => {
        onAfterLoginFunctions({ isKontextwechsel });
        $router.push({
          name: "start",
          force: true,
        });
      }
    );
  };

  const deleteErrors = () => {
    errors.value = undefined;
  };

  const login = () => {
    if (isLoginLoaded.value) {
      return;
    }
    deleteErrors();
    isLoginLoaded.value = true;
    postHttp({
      url: "auth/login/",
      data: {
        auth: isAdmin.value ? 3 : 2,
        username: model.value.username,
        password: model.value.password,
      },
    }).then(
      () => {
        onAfterLogin();
      },
      error => {
        if (isAdmin.value) {
          errors.value = error.data;
        } else {
          errors.value = {
            _error: "Bitte fragen Sie Ihre Leitung, ob Sie als Nutzer angelegt und mit einer Rolle versehen sind oder korrigieren Sie die folgenden Fehler:",
            ...error.data,
          };
        }
      }
    ).finally(() => {
      isLoginLoaded.value = false;
    });
  };

  return {
    errors,
    isLoginLoaded,
    login,
    onAfterLogin,
    onAfterLoginFunctions,
  };
}
