const STUDIENRICHTUNG_DETAILS = () => import(/* webpackChunkName: "StudienrichtungDetails" */ "../../Views/Universitaet/Studienrichtungen/StudienrichtungDetails/StudienrichtungDetails.vue");
const STUDIENRICHTUNGEN_LIST = () => import(/* webpackChunkName: "StudienrichtungenList" */ "../../Views/Universitaet/Studienrichtungen/StudienrichtungenList/StudienrichtungenList.vue");
const STUDIENGANG_DETAILS = () => import(/* webpackChunkName: "studiengangDetails" */ "../../Views/Universitaet/Studiengaenge/StudiengangDetails/StudiengangDetails.vue");
const STUDIENGAENGE_LIST = () => import(/* webpackChunkName: "studiengaengeList" */ "../../Views/Universitaet/Studiengaenge/StudiengaengeList/StudiengaengeList.vue");
const UNI_SCHULFACH_LIST = () => import(/* webpackChunkName: "schulfachList" */ "../../Views/Universitaet/Universitaetsschulfach/UniversitaetsschulfachList/UniversitaetsschulfachList.vue");
const UNI_SCHULFACH_DETAILS = () => import(/* webpackChunkName: "schulfachDetails" */ "../../Views/Universitaet/Universitaetsschulfach/UniversitaetsschulfachDetails/UniversitaetsschulfachDetails.vue");

export default [
  {
    path: "/studiengang-verwalten/",
    name: "studiengangVerwaltenList",
    component: STUDIENGAENGE_LIST,
    meta: {
      permissions: [
        "backend.universitycourse.view",
      ],
    },
  },
  {
    path: "/studiengang-verwalten/:id/",
    name: "studiengangVerwaltenDetails",
    component: STUDIENGANG_DETAILS,
    meta: {
      permissions: [
        "backend.universitycourse.view",
      ],
    },
  },
  {
    path: "/studienrichtung-verwalten/",
    name: "studienrichtungVerwaltenList",
    component: STUDIENRICHTUNGEN_LIST,
    meta: {
      permissions: [
        "backend.universitykeyaspect.view",
      ],
    },
  },
  {
    path: "/studienrichtung-verwalten/:id/",
    name: "studienrichtungVerwaltenDetails",
    component: STUDIENRICHTUNG_DETAILS,
    meta: {
      permissions: [
        "backend.universitykeyaspect.view",
      ],
    },
  },
  {
    path: "/universitaetsschulfach-verwalten/",
    name: "universitaetsschulfachVerwaltenList",
    component: UNI_SCHULFACH_LIST,
    meta: {
      permissions: [
        "backend.universitysubject.view",
      ],
    },
  },
  {
    path: "/universitaetsschulfach-verwalten/:id/",
    name: "universitaetsschulfachVerwaltenDetails",
    component: UNI_SCHULFACH_DETAILS,
    meta: {
      permissions: [
        "backend.universitysubject.view",
      ],
    },
  },
];
