import {
  computed,
} from "vue";

import InstanzenUniAPI, {
  currentInstanz,
  hasPermissionInstanzenView,
  modelInstanzen,
} from "../../../Views/Instanzen/compositionAPI/InstanzenUniAPI";

import {
  isEmpty,
} from "lodash-es";

const currentInstanzName = computed(() => {
  return currentInstanz.value.i_name || "";
});

const currentInstanzData = computed(() => {
  if (hasPermissionInstanzenView.value &&
    !isEmpty(currentInstanz.value)) {
    return [
      {
        id: "instanzdaten",
        label: "Instanzdaten",
        children: [
          {
            id: "instanzdatenUebersicht",
            label: "Übersicht anzeigen",
            to: {
              name: "instanzdatenUebersicht",
            },
          },
          {
            id: "instanzdatenFristen",
            label: "Fristen verwalten",
            to: {
              name: "instanzdatenFristen",
            },
          },
          {
            id: "instanzdatenInstanzKonfigurieren",
            label: "Instanz konfigurieren",
            to: {
              name: "instanzdatenInstanzKonfigurieren",
            },
          },
        ],
      },
      {
        id: "instanzdatenUniversitaet",
        label: "Universität",
        children: [
          {
            id: "instanzdatenStudiengang",
            label: "Studiengänge ansehen",
            to: {
              name: "instanzStudiengangList",
            },
          },
          {
            id: "instanzdatenStudienrichtungen",
            label: "Studienrichtungen ansehen",
            to: {
              name: "instanzStudienrichtungList",
            },
          },
          {
            id: "instanzdatenUniversitaetsschulfach",
            label: "Universitätsschulfächer ansehen",
            to: {
              name: "instanzUniversitaetsschulfachList",
            },
          },
          {
            id: "instanzdatenStudienrichtungsmerkmale",
            label: "Studienrichtungsmerkmale verwalten",
            to: {
              name: "studienrichtungsmerkmaleVerwalten",
            },
          },
          {
            id: "instanzdatenSchulseitigeMerkmale",
            label: "Schulseitige Merkmale zusammenführen",
            to: {
              name: "schulseitigeMerkmaleVerwalten",
            },
          },
          {
            id: "instanzdatenRegionalklassenVerwalten",
            label: "Regionalklassen verwalten",
            to: {
              name: "regionalklassenVerwaltenList",
            },
          },
        ],
      },
      {
        id: "instanzdatenOrganisationen",
        label: "Organisationen",
        children: [
          {
            id: "instanzdatenZfsl",
            label: "ZfsL anzeigen",
            to: {
              name: "instanzZfslList",
            },
          },
          {
            id: "instanzdatenSeminare",
            label: "Seminare anzeigen",
            to: {
              name: "instanzSeminarList",
            },
          },
          {
            id: "instanzdatenSchulen",
            label: "Schulen verwalten",
            to: {
              name: "instanzSchuleList",
            },
          },
        ],
      },
      {
        id: "instanzdatenDownloads",
        label: "Downloads ansehen",
        to: {
          name: "instanzDownloadsList",
        },
      },
      {
        id: "instanzdatenDokumente",
        label: "Dokumente",
        children: [
          {
            id: "instanzdatenTemplates",
            label: "Templates ansehen",
            to: {
              name: "instanzTemplatesList",
            },
          },
          {
            id: "instanzdatenStatischeDokumente",
            label: "Statische Dokumente verwalten",
            to: {
              name: "instanzStatischeDokumenteList",
            },
          },
        ],
      },
      {
        id: "instanzdatenStudierende",
        label: "Studierende",
        children: [
          {
            id: "instanzdatenStudierendeVerwalten",
            label: "Studierende verwalten",
            to: {
              name: "instanzStudierendeVerwaltenList",
            },
          },
          {
            id: "instanzdatenStudierendenmerkmale",
            label: "Studierendenmerkmale verwalten",
            to: {
              name: "instanzStudierendenmerkmaleVerwalten",
            },
          },
          {
            id: "instanzdatenStudierendeAnSchulen",
            label: "Studierende an Schulen",
            to: {
              name: "instanzStudierendeAnSchulenList",
            },
          },
          {
            id: "instanzdatenStudierendeAnSeminaren",
            label: "Studierende an Seminaren",
            to: {
              name: "instanzStudierendeAnSeminarenList",
            },
          },
          {
            id: "instanzdatenStudierendeSchulSeminarZuordnung",
            label: "Studierende je Schul-Seminar-Zuordnung",
            to: {
              name: "instanzStudierendeSchulSeminarZuordnungList",
            },
          },
        ],
      },
      {
        id: "instanzdatenVerteilung",
        label: "Verteilung",
        children: [
          {
            id: "instanzdatenKapazitaetspruefung",
            label: "Kapazitätsprüfung",
            to: {
              name: "instanzKapazitaetspruefung",
            },
          },
          {
            id: "instanzdatenVerteilrunden",
            label: "Verteilrunden",
            to: {
              name: "instanzVerteilrundenList",
            },
          },
          {
            id: "instanzdatenVerteilungsguete",
            label: "Verteilungsgüte",
            to: {
              name: "instanzVerteilungsgueteList",
            },
          },
        ],
      },
      {
        id: "instanzdatenKommunikation",
        label: "Kommunikation",
        children: [
          {
            id: "instanzdatenNachrichtentemplates",
            label: "Nachrichtentemplates ansehen",
            to: {
              name: "instanzNachrichtentemplatesList",
            },
          },
          {
            id: "instanzdatenNachrichtenEingang",
            label: "Nachrichten Eingang",
            to: {
              name: "instanzNachrichtenEingangList",
            },
          },
          {
            id: "instanzdatenNachrichtenGesendet",
            label: "Nachrichten Gesendet",
            to: {
              name: "instanzNachrichtenGesendetList",
            },
          },
        ],
      },
    ];
  }
  return [];
});

export const instanzenData = computed(() => {
  if (hasPermissionInstanzenView.value) {
    return [
      {
        id: "alleInstanzen",
        label: "Alle Instanzen",
        icon: "CloudsFill",
        children: [
          {
            id: "instanzen",
            label: "Instanzen verwalten",
            to: {
              name: "instanzenList",
            },
          },
        ],
      },
      {
        id: "instanzenSelect",
        label: modelInstanzen.value ? `Instanz ${ currentInstanzName.value } verwalten` : "Instanz wählen...",
        icon: "CloudFill",
        slot: "instanzenSelect",
        children: currentInstanzData.value
      },
    ];
  }
  return [];
});

export default function DataInstanzenAPI() {
  const {
    dataInstanzen,
    isLoadingInstanzen,
    updateModelInstanzen,
  } = InstanzenUniAPI();

  return {
    dataInstanzen,
    instanzenData,
    isLoadingInstanzen,
    modelInstanzen,
    updateModelInstanzen,
  };
}
