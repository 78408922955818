import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  forEach,
  keyBy,
} from "lodash-es";

const dynamischeTexte = ref({});
const dynamischeTexteMapping = ref({});
const dynamischeTexteLoading = ref(true);

export default function DynamischeTexteAPI() {
  const {
    getHttp,
  } = AHttpAPI();

  const setDynamischeTexteMapping = response => {
    const MAPPING = {};
    forEach(response, item => {
      MAPPING[item.dyt_key] = item.dyt_text;
    });
    dynamischeTexteMapping.value = MAPPING;
  };

  const loadDynamischeTexte = () => {
    return getHttp({
      url: "dyntext_ro/",
    }).then(
      response => {
        setDynamischeTexteMapping(response);
        dynamischeTexte.value = keyBy(response, "dyt_key");

        dynamischeTexteLoading.value = false;
      }
    );
  };

  return {
    dynamischeTexte,
    dynamischeTexteLoading,
    dynamischeTexteMapping,
    loadDynamischeTexte,
  };
}
