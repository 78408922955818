import {
  computed,
  ref,
} from "vue";
import {
  useRouter,
} from "vue-router";

import {
  getHttp,
  getListHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";
import {
  checkPermissionsSync,
} from "../../../global/compositionAPI/PermissionAPI";
import Cookies from "js-cookie";
import {
  forEach,
  get,
  isEmpty,
  keyBy, startsWith,
} from "lodash-es";
import moment from "moment";

export const modelInstanzen = ref(undefined);
const dataInstanzenKeyByPk = ref({});
const dataInstanzen = ref([]);
const isLoadingInstanzen = ref(undefined);

export const currentInstanz = computed(() => {
  if (modelInstanzen.value) {
    return dataInstanzenKeyByPk.value[modelInstanzen.value] || {};
  }
  return {};
});

export const isInstanzSelected = computed(() => {
  return !isEmpty(currentInstanz.value);
});

const COOKIES_INSTANZEN_NAME = "instanz";

export const hasPermissionInstanzenView = computed(() => {
  return checkPermissionsSync({
    permission: "backend.instance.list",
  });
});

export default function InstanzenUniAPI() {
  const $router = useRouter();

  const currentInstanzZeitraum = computed(() => {
    if (isInstanzSelected.value) {
      const START_MONTH = currentInstanz.value.i_name.indexOf("Februar") ?
        2 :
        9;
      const START_YEAR = +currentInstanz.value.i_name.slice(-4);
      const START_DATE = `${ START_YEAR }-0${ START_MONTH }-01`;

      const END_MONTH = START_MONTH === 2 ? 8 : 1;
      const END_YEAR = START_MONTH === 2 ? START_YEAR : START_YEAR + 1;
      const END_DATE = `${ END_YEAR }-0${ END_MONTH }-31`;

      const durationDays = moment(END_DATE).diff(START_DATE, "days") + 1;

      return {
        start: START_DATE,
        end: END_DATE,
        durationDays,
      };
    }
    return undefined;
  });

  const isInstanzFinalisiert = computed(() => {
    return get(currentInstanz.value, "status_obj.pk") === 5;
  });

  const removeTableCookies = () => {
    const ALL_COOKIES = Cookies.get();
    forEach(ALL_COOKIES, (_, key) => {
      if (startsWith(key, "table_")) {
        Cookies.remove(key);
      }
    });
  };

  const updateModelInstanzen = model => {
    modelInstanzen.value = model;
    removeTableCookies();
    Cookies.set(COOKIES_INSTANZEN_NAME, model);
    $router.push({
      name: "instanzdatenUebersicht",
    });
  };

  const removeInstanzen = () => {
    dataInstanzen.value = [];
    dataInstanzenKeyByPk.value = {};
    Cookies.remove(COOKIES_INSTANZEN_NAME);
    modelInstanzen.value = undefined;
  };

  const reloadCurrentInstanz = () => {
    if (!modelInstanzen.value) {
      return;
    }
    return getHttp({
      url: `instanzen/${ modelInstanzen.value }/`,
    }).then(
      response => {
        dataInstanzenKeyByPk.value[modelInstanzen.value] = response;
      }
    );
  };

  return {
    currentInstanz,
    currentInstanzZeitraum,
    dataInstanzen,
    dataInstanzenKeyByPk,
    hasPermissionInstanzenView,
    isInstanzFinalisiert,
    isInstanzSelected,
    isLoadingInstanzen,
    loadInstanzen,
    modelInstanzen,
    reloadCurrentInstanz,
    removeInstanzen,
    updateModelInstanzen,
  };
}

export function loadInstanzen({ reload = false } = {}) {
  if (!hasPermissionInstanzenView.value ||
    isLoadingInstanzen.value) {
    return;
  }
  if (!reload && dataInstanzen.value &&
    dataInstanzen.value.length) {
    return;
  }
  isLoadingInstanzen.value = true;
  return getListHttp({
    url: "instanzen/",
    urlParams: {
      ordering: "-semester__prn_beginn",
      fields: [
        "pk",
        "i_name",
        "in_betrieb",
        "status_obj",
        "user_permissions",
        "semester",
        "has_schulmerkmale",
	      "i_verteilung_sichtbar",
	      "permission_hints",
        "i_bestaetigung_zuweisung",
      ],
      limit: 10000,
    },
  }).then(
    response => {
      dataInstanzen.value = response;
      dataInstanzenKeyByPk.value = keyBy(response, "pk");
      setModelInstanzenFromCookies();
      isLoadingInstanzen.value = false;
    }
  );
}

function setModelInstanzenFromCookies() {
  const MODEL_INSTANZEN_FROM_COOKIES = Cookies.get(COOKIES_INSTANZEN_NAME);
  if (MODEL_INSTANZEN_FROM_COOKIES && dataInstanzenKeyByPk.value[MODEL_INSTANZEN_FROM_COOKIES]) {
    modelInstanzen.value = +MODEL_INSTANZEN_FROM_COOKIES;
  } else {
    Cookies.remove(COOKIES_INSTANZEN_NAME);
  }
}

export function updateModelInstanzenWithoutRedirect(model) {
  if (model && dataInstanzenKeyByPk.value[model]) {
    modelInstanzen.value = model;
    Cookies.set(COOKIES_INSTANZEN_NAME, model);
  }
}
