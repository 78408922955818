import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

export default function DataFormAPI() {
  const {
    getHttp,
  } = AHttpAPI();

  const loadingUsers = ref(true);
  const users = ref([]);

  const loadUsers = () => {
    return getHttp({
      url: "profil/nutzer/",
    }).then(
      response => {
        users.value = response;
        loadingUsers.value = false;
      },
    );
  };


  return {
    loadingUsers,
    loadUsers,
    users,
  };
}
