const AUSSCHLUSSGRUND_DETAILS = () => import(/* webpackChunkName: "ausschlussgrundDetails" */ "../../Views/Administration/Ausschlussgrund/AusschlussgrundDetails/AusschlussgrundDetails.vue");
const AUSSCHLUSSGRUND_LIST = () => import(/* webpackChunkName: "ausschlussgrundList" */ "../../Views/Administration/Ausschlussgrund/AusschlussgrundList/AusschlussgrundList.vue");
const BRANDING_DETAILS = () => import(/* webpackChunkName: "brandingDetails" */ "../../Views/Administration/Branding/BrandingDetails/BrandingDetails.vue");
const FaqDetails = () => import(/* webpackChunkName: "seminartypenList" */ "../../Views/FAQ/FaqDetails/FaqDetails.vue");
const FristEFZErinnerung = () => import(/* webpackChunkName: "fristEFZErinnerung" */ "../../Views/Administration/FristEFZErinnerung/FristEFZErinnerungDetails/FristEFZErinnerungDetails.vue");
const FRISTEN_DETAILS = () => import(/* webpackChunkName: "fristenDetails" */ "../../Views/Administration/Fristen/FristenDetails/FristenDetails.vue");
const FRISTEN_LIST = () => import(/* webpackChunkName: "fristenList" */ "../../Views/Administration/Fristen/FristenList/FristenList.vue");
const HILFETHEMEN_DETAILS = () => import(/* webpackChunkName: "hilfethemenDetails" */ "../../Views/Administration/HilfethemenPage/HilfethemenDetails/HilfethemenDetails.vue");
const HILFETHEMEN_LIST = () => import(/* webpackChunkName: "hilfethemenList" */ "../../Views/Administration/HilfethemenPage/HilfethemenList/HilfethemenList.vue");
const LogEintraegeDetails = () => import(/* webpackChunkName: "logEintraegeDetails" */ "../../Views/Administration/LogEintraegeDetails/LogEintraegeDetails.vue");
const MELDUNGEN_DETAILS = () => import(/* webpackChunkName: "meldungenList" */ "../../Views/Administration/Meldungen/MeldungenDetails/MeldungenDetails.vue");
const MELDUNGEN_LIST = () => import(/* webpackChunkName: "meldungenList" */ "../../Views/Administration/Meldungen/MeldungenList/MeldungenList.vue");
const ORGANISATIONSDATEN_DETAILS = () => import(/* webpackChunkName: "OrganisationsdatenDetails" */ "../../Views/Administration/Organisationsdaten/OrganisationsdatenDetails/OrganisationsdatenDetails.vue");
const SCHULFAECHER_LIST = () => import(/* webpackChunkName: "schulfaecherList" */ "../../Views/Administration/Schulfaecher/SchulfaecherList/SchulfaecherList.vue");
const SCHULFORMEN_LIST = () => import(/* webpackChunkName: "schulformenList" */ "../../Views/Administration/Schulformen/SchulformenList/SchulformenList.vue");
const SCHULTRAEGER_DETAILS = () => import(/* webpackChunkName: "schultraegerDetails" */ "../../Views/Administration/Schultraeger/SchultraegerDetails/SchultraegerDetails.vue");
const SCHULTRAEGER_LIST = () => import(/* webpackChunkName: "schultraegerList" */ "../../Views/Administration/Schultraeger/SchultraegerList/SchultraegerList.vue");
const SEMINARTYPEN_DETAILS = () => import(/* webpackChunkName: "seminartypenDetails" */ "../../Views/Administration/Seminartypen/SeminartypenDetails/SeminartypenDetails.vue");
const SEMINARTYPEN_LIST = () => import(/* webpackChunkName: "seminartypenList" */ "../../Views/Administration/Seminartypen/SeminartypenList/SeminartypenList.vue");


export default [
  {
    path: "/admin/efz-frist/",
    name: "adminFristEFZErinnerung",
    component: FristEFZErinnerung,
    meta: {
      permissions: [
        "backend.efz_period.view",
      ],
    },
  },
  {
    path: "/admin/meldung/",
    name: "adminMeldungList",
    component: MELDUNGEN_LIST,
    meta: {
      permissions: [
        "backend.dyntext.view",
      ],
    },
  },
  {
    path: "/admin/meldung/:id/",
    name: "adminMeldungDetails",
    component: MELDUNGEN_DETAILS,
    meta: {
      permissions: [
        "backend.dyntext.view",
      ],
    },
  },
  {
    path: "/admin/organisation/",
    name: "adminOrganisation",
    component: ORGANISATIONSDATEN_DETAILS,
    meta: {
      permissions: [
        "backend.client.edit_own",
      ],
    },
  },
  {
    path: "/admin/branding/",
    name: "adminBranding",
    component: BRANDING_DETAILS,
    meta: {
      permissions: [
        "backend.branding.edit"
      ],
    },
  },
  {
    path: "/admin/hilfethemen/",
    name: "hilfethemenList",
    component: HILFETHEMEN_LIST,
    meta: {
      permissions: [
        "backend.topic.view"
      ],
    },
  },
  {
    path: "/admin/hilfethemen/:id/",
    name: "hilfethemenDetails",
    component: HILFETHEMEN_DETAILS,
    meta: {
      permissions: [
        "backend.topic.view"
      ],
    },
  },
  {
    path: "/admin/ausschlussgruende/",
    name: "ausschlussgrundList",
    component: AUSSCHLUSSGRUND_LIST,
    meta: {
      permissions: [
        "backend.capacityreason.view",
      ],
    },
  },
  {
    path: "/admin/ausschlussgruende/:id/",
    name: "ausschlussgrundDetails",
    component: AUSSCHLUSSGRUND_DETAILS,
    meta: {
      permissions: [
        "backend.capacityreason.view",
      ],
    },
  },
  {
    path: "/admin/schulfaecher/",
    name: "schulfaecherList",
    component: SCHULFAECHER_LIST,
    meta: {
      permissions: [
        "backend.schoolsubject.view",
      ],
    },
  },
  {
    path: "/admin/schulformen/",
    name: "schulformenList",
    component: SCHULFORMEN_LIST,
    meta: {
      permissions: [
        "backend.schooltype.view",
      ],
    },
  },
  {
    path: "/admin/schultraeger/",
    name: "schultraegerList",
    component: SCHULTRAEGER_LIST,
    meta: {
      permissions: [
        "backend.educationboard.view",
      ],
    },
  },
  {
    path: "/admin/schultraeger/:id/",
    name: "schultraegerDetails",
    component: SCHULTRAEGER_DETAILS,
    meta: {
      permissions: [
        "backend.educationboard.view",
      ],
    },
  },
  {
    path: "/admin/frist/",
    name: "fristList",
    component: FRISTEN_LIST,
    meta: {
      permissions: [
        "backend.practicumdeadline.view",
      ],
    },
  },
  {
    path: "/admin/frist/:id/",
    name: "fristDetails",
    component: FRISTEN_DETAILS,
    meta: {
      permissions: [
        "backend.practicumdeadline.view",
      ],
    },
  },
  {
    path: "/admin/seminartyp/",
    name: "seminartypList",
    component: SEMINARTYPEN_LIST,
    meta: {
      permissions: [
        "backend.seminartype.view",
      ],
    },
  },
  {
    path: "/admin/seminartyp/:id/",
    name: "seminartypDetails",
    component: SEMINARTYPEN_DETAILS,
    meta: {
      permissions: [
        "backend.seminartype.view",
      ],
    },
  },
  {
    path: "/admin/log-eintraege/",
    name: "logEintraege",
    component: LogEintraegeDetails,
    meta: {
      permissions: [
        "backend.logentry_view_all",
      ],
    },
  },
  {
    path: "/admin/faq/",
    name: "faqDetailsAdmin",
    component: FaqDetails,
    meta: {
      permissions: [
        "backend.faq.view",
      ],
    },
    props: {
      readonly: false,
    },
  },
];
