import {
  computed,
} from "vue";

import EventBus from "../../../global/functions/EventBus";

export default function MenuAPI({
  breadcrumbItems = computed(() => []),
}) {
  const openMenuPanel = ({ index }) => {
    const PANELS = [];
    for (let i = index; i > -1; i--) {
      const ITEM = breadcrumbItems.value[i];
      if (ITEM.menuId) {
        PANELS.unshift(ITEM.menuId);
      } else {
        break;
      }
    }
    EventBus.$emit("openMenuPanel", {
      panels: PANELS,
    });
  };

  return {
    openMenuPanel,
  };
}
