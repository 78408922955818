import AButton from "aloha-vue/src/AButton/AButton";

import EventsAPI from "./compositionAPI/EventsAPI";
import OrganisationAPI from "./compositionAPI/OrganisationAPI";

// @vue/component
export default {
  name: "KontextwechselModalUser",
  components: {
    AButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: [
    "selectUser",
  ],
  setup(props, context) {
    const {
      organisation,
    } = OrganisationAPI(props);

    const {
      selectUser,
    } = EventsAPI(props, context);

    return {
      organisation,
      selectUser,
    };
  },
};
