import AConfirmAPI from "aloha-vue/src/compositionAPI/AConfirmAPI";


export default function TitleStudierendeAPI() {
  const {
    openConfirm,
  } = AConfirmAPI();

  const showMenuLinkInfo = ({ item }) => {
    openConfirm({
      bodyHtml: item.titleHtml,
      selectorCloseIds: `btn_menu_info_${ item.id }`,
      headerText: item.label,
      closeButtonText: "Schließen",
    });
  };

  return {
    showMenuLinkInfo,
  };
}
