import ArchiveFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArchiveFill";
import ArrowClockwise from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowClockwise";
import ArrowCounterclockwise from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowCounterclockwise";
import ArrowLeft from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowLeft";
import ArrowLeftShort from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowLeftShort";
import ArrowsMove from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowsMove";
import ArrowRepeat from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowRepeat";
import ArrowRight from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowRight";
import ArrowRightCircleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowRightCircleFill";
import ArrowRightShort from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ArrowRightShort";
import BarChartLineFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/BarChartLineFill";
import Bookmark from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Bookmark";
import BookmarkFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/BookmarkFill";
import BoxArrowInUpRight from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/BoxArrowInUpRight";
import Bug from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Bug";
import Calendar3 from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Calendar3";
import ChatDotsFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChatDotsFill";
import Check from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Check";
import Check2Square from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Check2Square";
import CheckCircle from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CheckCircle";
import CheckCircleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CheckCircleFill";
import CheckLg from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CheckLg";
import CheckSquare from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CheckSquare";
import CheckSquareFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CheckSquareFill";
import ChevronDoubleDown from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronDoubleDown";
import ChevronDoubleRight from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronDoubleRight";
import ChevronDoubleUp from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronDoubleUp";
import ChevronDown from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronDown";
import ChevronLeft from "aloha-vue/src/AIcon/Icons/bootstrap3/ChevronLeft";
import ChevronLeftThin from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronLeft";
import ChevronRight from "aloha-vue/src/AIcon/Icons/bootstrap3/ChevronRight";
import ChevronRightThin from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronRight";
import ChevronUp from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ChevronUp";
import Clock from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Clock";
import Cloud from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Cloud";
import CloudFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CloudFill";
import CloudsFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/CloudsFill";
import Cog from "aloha-vue/src/AIcon/Icons/bootstrap3/Cog";
import Copy from "aloha-vue/src/AIcon/Icons/bootstrap3/Copy";
import DashCircleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/DashCircleFill";
import DashSquare from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/DashSquare";
import Diagram3Fill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Diagram3Fill";
import Display from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Display";
import Download from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Download";
import Duplicate from "aloha-vue/src/AIcon/Icons/bootstrap3/Duplicate";
import Education from "./icons/Education";
import Envelope from "aloha-vue/src/AIcon/Icons/bootstrap3/Envelope";
import EnvelopeOpen from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/EnvelopeOpen";
import EraserFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/EraserFill";
import ExclamationTriangleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ExclamationTriangleFill";
import Export from "aloha-vue/src/AIcon/Icons/bootstrap3/Export";
import Eye from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Eye";
import EyeSlash from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/EyeSlash";
import EyeSlashFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/EyeSlashFill";
import FileEarmarkText from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/FileEarmarkText";
import FiletypeCsv from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/FiletypeCsv";
import Flag from "aloha-vue/src/AIcon/Icons/bootstrap3/Flag";
import FlagEmpty from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Flag";
import FlagFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/FlagFill";
import FolderOpen from "aloha-vue/src/AIcon/Icons/bootstrap3/FolderOpen";
import FolderSymlink from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/FolderSymlink";
import GeoAltFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/GeoAltFill";
import Globe from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Globe";
import HandThumbsUpFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/HandThumbsUpFill";
import Home from "aloha-vue/src/AIcon/Icons/bootstrap3/Home";
import InfoCircle from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/InfoCircle";
import InfoCircleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/InfoCircleFill";
import InfoSign from "aloha-vue/src/AIcon/Icons/bootstrap3/InfoSign";
import Key from "aloha-vue/src/AIcon/Icons/bootstrap3/Key";
import ListUl from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ListUl";
import Lock from "./icons/Lock";
import LockOpen from "./icons/LockOpen";
import Magnet from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Magnet";
import Minus from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/DashLg";
import MinusSign from "aloha-vue/src/AIcon/Icons/bootstrap3/MinusSign";
import Off from "aloha-vue/src/AIcon/Icons/bootstrap3/Off";
import Paperclip from "aloha-vue/src/AIcon/Icons/bootstrap3/Paperclip";
import PassFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PassFill";
import Paste from "aloha-vue/src/AIcon/Icons/bootstrap3/Paste";
import Pencil from "aloha-vue/src/AIcon/Icons/bootstrap3/Pencil";
import PencilSquare from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PencilSquare";
import PeopleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PeopleFill";
import PersonFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PersonFill";
import PersonXFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PersonXFill";
import PinFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PinFill";
import PlayFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PlayFill";
import Plus from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PlusLg";
import PlusSign from "aloha-vue/src/AIcon/Icons/bootstrap3/PlusSign";
import PlusSquare from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/PlusSquare";
import QuestionCircleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/QuestionCircleFill";
import RecordCircle from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/RecordCircle";
import ReplyFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ReplyFill";
import Save from "aloha-vue/src/AIcon/Icons/bootstrap3/Save";
import SaveFile from "aloha-vue/src/AIcon/Icons/bootstrap3/SaveFile";
import Search from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Search";
import Square from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Square";
import SquareHalf from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/SquareHalf";
import Star from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Star";
import StarFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/StarFill";
import Stickies from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Stickies";
import StickyFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/StickyFill";
import StopFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/StopFill";
import TagFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/TagFill";
import Tags from "aloha-vue/src/AIcon/Icons/bootstrap3/Tags";
import TagsFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/TagsFill";
import ThreeDots from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/ThreeDots";
import Time from "aloha-vue/src/AIcon/Icons/bootstrap3/Time";
import Transfer from "aloha-vue/src/AIcon/Icons/bootstrap3/Transfer";
import Trash from "aloha-vue/src/AIcon/Icons/bootstrap3/Trash";
import Upload from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/Upload";
import User from "aloha-vue/src/AIcon/Icons/bootstrap3/User";
import WeightForScale from "aloha-vue/src/AIcon/Icons/WeightForScale";
import WeightForScaleEmpty from "aloha-vue/src/AIcon/Icons/WeightForScaleEmpty";
import X from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/X";
import XCircle from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/XCircle";
import XCircleFill from "aloha-vue/src/AIcon/Icons/bootstrap-1-9-1/XCircleFill";

export default {
  ArchiveFill,
  ArrowClockwise,
  ArrowCounterclockwise,
  ArrowLeft,
  ArrowLeftShort,
  ArrowsMove,
  ArrowRepeat,
  ArrowRight,
  ArrowRightCircleFill,
  ArrowRightShort,
  BarChartLineFill,
  Bookmark,
  BookmarkFill,
  BoxArrowInUpRight,
  Bug,
  Calendar3,
  ChatDotsFill,
  Check,
  Check2Square,
  CheckCircle,
  CheckCircleFill,
  CheckLg,
  CheckSquare,
  CheckSquareFill,
  ChevronDoubleDown,
  ChevronDoubleRight,
  ChevronDoubleUp,
  ChevronDown,
  ChevronLeft,
  ChevronLeftThin,
  ChevronRight,
  ChevronRightThin,
  ChevronUp,
  Clock,
  Cloud,
  CloudFill,
  CloudsFill,
  Cog,
  Copy,
  DashCircleFill,
  DashSquare,
  Diagram3Fill,
  Display,
  Download,
  Duplicate,
  Education,
  Envelope,
  EnvelopeOpen,
  EraserFill,
  ExclamationTriangleFill,
  Export,
  Eye,
  EyeSlash,
  EyeSlashFill,
  FileEarmarkText,
  FiletypeCsv,
  Flag,
  FlagEmpty,
  FlagFill,
  FolderOpen,
  FolderSymlink,
  GeoAltFill,
  Globe,
  HandThumbsUpFill,
  Home,
  InfoCircle,
  InfoCircleFill,
  InfoSign,
  Key,
  ListUl,
  Lock,
  LockOpen,
  Magnet,
  Minus,
  MinusSign,
  Off,
  Paperclip,
  PassFill,
  Paste,
  Pencil,
  PencilSquare,
  PeopleFill,
  PersonFill,
  PersonXFill,
  PinFill,
  PlayFill,
  Plus,
  PlusSign,
  PlusSquare,
  QuestionCircleFill,
  RecordCircle,
  ReplyFill,
  Save,
  SaveFile,
  Search,
  Square,
  SquareHalf,
  Star,
  StarFill,
  Stickies,
  StickyFill,
  StopFill,
  TagFill,
  Tags,
  TagsFill,
  ThreeDots,
  Time,
  Transfer,
  Trash,
  Upload,
  User,
  WeightForScale,
  WeightForScaleEmpty,
  X,
  XCircle,
  XCircleFill,
};
