import {
  computed,
  ref,
} from "vue";

import {
  cloneDeep,
  isArray,
  isPlainObject,
} from "lodash-es";

const breadcrumbHome = {
  icon: "Home",
  label: "Startseite",
  to: {
    name: "verwaltung",
  },
};


const breadcrumbPages = ref([]);

export default function TheBreadcrumbAPI() {
  const breadcrumbItems = computed(() => {
    return [
      breadcrumbHome,
      ...breadcrumbPages.value,
    ];
  });

  const setBreadcrumbPages = breadcrumb => {
    breadcrumbPages.value = [];
    if (isArray(breadcrumb)) {
      breadcrumbPages.value = cloneDeep(breadcrumb);
    } else if (isPlainObject(breadcrumb)) {
      breadcrumbPages.value.push(cloneDeep(breadcrumb));
    }
  };

  return {
    breadcrumbItems,
    setBreadcrumbPages,
  };
}
