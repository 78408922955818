const DYNAMISCHE_DOKUMENTE_DETAILS = () => import(/* webpackChunkName: "dynamischeDokumenteDetails" */ "../../Views/Dokumente/DynamischeDokumente/DynamischeDokumenteDetails/DynamischeDokumenteDetails.vue");
const DYNAMISCHE_DOKUMENTE_LIST = () => import(/* webpackChunkName: "dynamischeDokumenteList" */ "../../Views/Dokumente/DynamischeDokumente/DynamischeDokumenteList/DynamischeDokumenteList.vue");
const STATISCHE_DOKUMENTE_DETAILS = () => import(/* webpackChunkName: "statischeDokumenteDetails" */ "../../Views/Dokumente/StatischeDokumente/StatischeDokumenteDetails/StatischeDokumenteDetails.vue");
const STATISCHE_DOKUMENTE_LIST = () => import(/* webpackChunkName: "statischeDokumenteList" */ "../../Views/Dokumente/StatischeDokumente/StatischeDokumenteList/StatischeDokumenteList.vue");

export default [
  {
    path: "/dokumente/",
    name: "statischeDokumenteList",
    component: STATISCHE_DOKUMENTE_LIST,
    meta: {
      permissions: [
        "backend.static_docs.view",
      ],
    },
  },
  {
    path: "/dokumente/:id/",
    name: "statischeDokumenteDetails",
    component: STATISCHE_DOKUMENTE_DETAILS,
    meta: {
      permissions: [
        "backend.static_docs.view",
      ],
    },
  },
  {
    path: "/dynamische-dokumente/",
    name: "dynamischeDokumenteList",
    component: DYNAMISCHE_DOKUMENTE_LIST,
    meta: {
      permissions: [
        "backend.templates.view",
      ],
    },
  },
  {
    path: "/dynamische-dokumente/:id/",
    name: "dynamischeDokumenteDetails",
    component: DYNAMISCHE_DOKUMENTE_DETAILS,
    meta: {
      permissions: [
        "backend.templates.view",
      ],
    },
  },
];
