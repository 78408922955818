import {
  computed,
} from "vue";
import {
  useRoute,
} from "vue-router";

import InstanzenUniAPI from "../../../Views/Instanzen/compositionAPI/InstanzenUniAPI";

export default function TextHeaderAPI() {
  const $route = useRoute();

  const {
    currentInstanz,
    isInstanzSelected,
  } = InstanzenUniAPI();

  const textHeader = computed(() => {
    if (isInstanzSelected.value && $route.meta.instanz) {
      return currentInstanz.value.i_name;
    }
    return "Vergabe von Praktikumsplätzen";
  });

  return {
    textHeader,
  };
}
