import AIcon from "aloha-vue/src/AIcon/AIcon";
import ALink from "aloha-vue/src/ALink/ALink";

import RestAPI from "./compositionAPI/RestAPI";

// @vue/component
export default {
  name: "TheNavbarNewsItem",
  components: {
    AIcon,
    ALink,
  },
  props: {
    newsData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      newsDate,
      newsNutzerObj,
      newsNutzerObjOrganisationObj,
    } = RestAPI(props);

    return {
      newsDate,
      newsNutzerObj,
      newsNutzerObjOrganisationObj,
    };
  },
};
