import {
  toRef,
} from "vue";

export default function EventsAPI(props, { emit }) {
  const user = toRef(props, "user");
  const selectUser = () => {
    emit("selectUser", { user: user.value });
  };

  return {
    selectUser,
  };
}
