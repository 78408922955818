import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import UserAPI from "./UserAPI";

import {
  keyBy,
} from "lodash-es";

const hilfethemen = ref({});
const hilfethemenLoading = ref(true);

export default function HilfethemenAPI() {
  const {
    getHttp,
  } = AHttpAPI();

  const {
    isAuthenticated,
  } = UserAPI();

  const loadHilfethemen = () => {
    if (!isAuthenticated.value) {
      hilfethemenLoading.value = false;
      return;
    }
    return getHttp({
      url: "hilfethemen_ro/",
    }).then(
      response => {
        hilfethemen.value = keyBy(response, "to_key");
        hilfethemenLoading.value = false;
      }
    );
  };

  return {
    hilfethemen,
    hilfethemenLoading,
    loadHilfethemen,
  };
}
