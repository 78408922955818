const LoginPage = () => import(/* webpackChunkName: "login" */ "../../Views/Registration/LoginPage/LoginPage.vue");
const LogoutPage = () => import(/* webpackChunkName: "logoutPage" */ "../../Views/Registration/LogoutPage/LogoutPage.vue");
const KontextauswahlPage = () => import(/* webpackChunkName: "kontextauswahlPage" */ "../../Views/Registration/KontextauswahlPage/KontextauswahlPage.vue");
const PasswordChangeRequired = () => import(/* webpackChunkName: "passwordChangeRequired" */ "../../Views/Registration/PasswordChangeRequired/PasswordChangeRequired.vue");
export default [
  {
    path: "/anmelden/",
    name: "login",
    component: LoginPage,
    meta: {
      isNotLogged: true,
    },
    props: {
      isAdmin: false,
    },
  },
  {
    path: "/anmelden-test/",
    name: "loginTest",
    component: LoginPage,
    meta: {
      isNotLogged: true,
    },
    props: {
      isAdmin: false,
    },
  },
  {
    path: "/verwaltung/anmelden/admin/",
    name: "loginAdmin",
    component: LoginPage,
    meta: {
      isNotLogged: true,
    },
    props: {
      isAdmin: true,
    },
  },
  {
    path: "/loginadmin/",
    name: "loginAdminIntern",
    component: LoginPage,
    meta: {
      isNotLogged: true,
    },
    props: {
      isAdmin: true,
    },
  },
  {
    path: "/kontextauswahl/",
    name: "kontextauswahlPage",
    component: KontextauswahlPage,
  },
  {
    path: "/password-change/",
    name: "passwordChangeRequired",
    component: PasswordChangeRequired,
  },
  {
    path: "/logout/",
    name: "logoutPage",
    component: LogoutPage,
  },
];
