import {
  ref,
} from "vue";

import {
  getHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";
import {
  isStudierende,
} from "../../../global/compositionAPI/UserAPI";
import {
  modelStudierendeInstanzen,
} from "./StudierendeInstanzenAPI";

import {
  isEmpty,
} from "lodash-es";


export const studierendeInstanzenStatuses = ref({});

export function loadStudierendeInstanzenStatuses({ isUpdate = true } = {}) {
  if (!isStudierende.value ||
    !modelStudierendeInstanzen.value) {
    return;
  }
  if (!isEmpty(studierendeInstanzenStatuses.value) && !isUpdate) {
    return;
  }
  return getHttp({
    url: `studentinstanzen/${ modelStudierendeInstanzen.value }/status/`,
  }).then(
    response => {
      studierendeInstanzenStatuses.value = response;
    }
  );
}

export function removeStudierendeInstanzenStatuses() {
  studierendeInstanzenStatuses.value = [];
}
