import {
  computed,
  ref,
} from "vue";

import UserAPI from "../../../../../global/compositionAPI/UserAPI";

import {
  get,
} from "lodash-es";

export default function KontextwechselModalAPI({
  callbackAfter = () => {},
} = {}) {
  const isKontextwechselModalVisible = ref(undefined);

  const {
    me,
  } = UserAPI();

  const isBtnKontextwechselVisible = computed(() => {
    return get(me.value, "multiple_users");
  });

  const openKontextwechselModal = () => {
    isKontextwechselModalVisible.value = true;
  };

  const closeKontextwechselModal = ({ isSave, response } = {}) => {
    if (isSave) {
      callbackAfter(response);
    }
    isKontextwechselModalVisible.value = false;
  };

  return {
    closeKontextwechselModal,
    isBtnKontextwechselVisible,
    isKontextwechselModalVisible,
    openKontextwechselModal,
  };
}
