import {
  computed,
  toRef,
} from "vue";

import AIcon from "aloha-vue/src/AIcon/AIcon";

import {
  get,
} from "lodash-es";

// @vue/component
export default {
  name: "TheMenuInstanzenSelectItem",
  components: {
    AIcon,
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    label: {
      type: String,
      required: true,
    },
    labelFiltered: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const item = toRef(props, "item");
    const label = toRef(props, "label");
    const icon = computed(() => {
      return item.value.in_betrieb ? "LockOpen" : "Lock";
    });

    const statusName = computed(() => {
      return get(item.value, "status_obj.ist_name");
    });

    const title = computed(() => {
      return `${ label.value }: ${ statusName.value }`;
    });

    return {
      icon,
      title,
    };
  },
};
