import {
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";

import EventBus from "../../../global/functions/EventBus";
import {
  setFocusToFirstLinkInPanel
} from "aloha-vue/src/AMenu/utils/utils";
import {
  cloneDeep, 
  get,
  last,
} from "lodash-es";

export default function AMenuUpdateAPI() {
  const aMenuRef = ref(undefined);

  const openMenuPanel = ({ panels }) => {
    if (aMenuRef.value &&
      panels &&
      panels.length) {
      aMenuRef.value.toggleMenu({ isOpen: true });
      aMenuRef.value.togglePanel({ parentIds: cloneDeep(panels) });
      setFocusToFirstLinkInPanel(last(panels));
    }
  };

  const setDefaultMenu = () => {
    if (get(aMenuRef.value, "setDefaultMenu")) {
      aMenuRef.value.setDefaultMenu();
    }
  };

  onMounted(() => {
    EventBus.$on("openMenuPanel", openMenuPanel);
    EventBus.$on("setDefaultMenu", setDefaultMenu);
  });

  onBeforeUnmount(() => {
    EventBus.$off("openMenuPanel", openMenuPanel);
    EventBus.$off("setDefaultMenu", setDefaultMenu);
  });

  return {
    aMenuRef,
  };
}
