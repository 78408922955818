export default `<svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  width="18"
  height="18"
  viewBox="0 0 18 18"
>
  <g
    transform="matrix(0.01752721,0,0,0.01752721,-3.505441e-5,0.68469016)"
  >
    <path
      d="M 1027.006,290.298 520.645,514.906 220.639,379.978 l -61.903,47.62 v 177.781 l 79.366,119.05 V 882.368 L 119.052,764.904 0.002,883.159 V 724.423 L 79.368,605.373 V 406.955 q 0,-3.174 -0.393,-8.332 -0.393,-5.158 0,-7.537 0.393,-2.379 0.796,-6.347 0.403,-3.968 2.378,-6.347 1.975,-2.379 5.159,-4.765 L 124.606,341.881 7.934,290.295 517.472,65.687 Z M 793.668,597.452 515.883,729.203 238.098,597.452 V 480.78 L 515.883,611.738 793.668,480.78 Z"
    />
  </g>
</svg>`;
