const SCHULE_STUDIERENDE_LIST = () => import(/* webpackChunkName: "schulenStudierendeList" */ "../../Views/Praxissemester/SchulenStudierende/SchulenStudierendeList/SchulenStudierendeList.vue");
const SCHULEN_FAECHER_LIST = () => import(/* webpackChunkName: "schulenFaecherList" */ "../../Views/Praxissemester/SchulenFaecher/SchulenFaecherList/SchulenFaecherList.vue");
const STUDIERENDE_DETAILS = () => import(/* webpackChunkName: "studierendeDetails" */ "../../Views/Praxissemester/Studierende/StudierendeDetails/StudierendeDetails.vue");
const STUDIERENDE_LIST = () => import(/* webpackChunkName: "studierendeList" */ "../../Views/Praxissemester/Studierende/StudierendeList/StudierendeList.vue");
const SCHUL_SEMINAR_STUDIERENDE_LIST = () => import(/* webpackChunkName: "schulSeminarStudierendeList" */ "../../Views/Praxissemester/SchulSeminarStudierende/SchulSeminarStudierendeList/SchulSeminarStudierendeList.vue");
const STUDIERENDE_AN_SEMINAREN_LIST = () => import(/* webpackChunkName: "studierendeAnSeminarenList" */ "../../Views/Praxissemester/StudierendeAnSeminaren/StudierendeAnSeminarenList/StudierendeAnSeminarenList.vue");
const SCHULEN_ANSEHEN_LIST = () => import(/* webpackChunkName: "schulenAnsehenList" */ "../../Views/Praxissemester/SchulenAnsehenList/SchulenAnsehenList.vue");
const RESTPLATZVERGABE_LIST = () => import(/* webpackChunkName: "restplatzvergabeList" */ "../../Views/Praxissemester/Restplatzvergabe/RestplatzvergabeList/RestplatzvergabeList.vue");

export default [
  {
    path: "/praxissemester/schulen-ansehen/",
    name: "schulenAnsehenList",
    component: SCHULEN_ANSEHEN_LIST,
    meta: {
      permissions: [
        "backend.reports.instseminarschools",
      ],
    },
  },
  {
    path: "/praxissemester/studierende/",
    name: "studierendeList",
    component: STUDIERENDE_LIST,
    meta: {
      permissions: [
        "backend.assigned_student.view",
      ],
    },
  },
  {
    path: "/praxissemester/studierende/:id/",
    name: "studierendeDetails",
    component: STUDIERENDE_DETAILS,
    meta: {
      permissions: [
        "backend.assigned_student.view",
      ],
    },
  },
  {
    path: "/praxissemester/schulen_faecher/",
    name: "schulenFaecherList",
    component: SCHULEN_FAECHER_LIST,
    meta: {
      permissions: [
        "backend.reports.schoolsubjects",
      ],
    },
  },
  {
    path: "/praxissemester/schulen_studierende/",
    name: "schulenStudierendeList",
    component: SCHULE_STUDIERENDE_LIST,
    meta: {
      permissions: [
        "backend.reports.schoolassignments",
      ],
    },
  },
  {
    path: "/praxissemester/seminare_studierende/",
    name: "seminareStudierendeList",
    component: STUDIERENDE_AN_SEMINAREN_LIST,
    meta: {
      permissions: [
        "backend.reports.seminarassignments",
      ],
    },
  },
  {
    path: "/praxissemester/schulseminar_studierende/",
    name: "schulseminarStudierendeList",
    component: SCHUL_SEMINAR_STUDIERENDE_LIST,
    meta: {
      permissions: [
        "backend.reports.seminarschoolassignments",
      ],
    },
  },
  {
    path: "/praxissemester/restplaetze/",
    name: "restplaetzeList",
    component: RESTPLATZVERGABE_LIST,
    meta: {
      permissions: [
        "backend.pooling.view",
      ],
    },
  },
];
