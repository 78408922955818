import TheBreadcrumbInstanzItem from "./TheBreadcrumbInstanzItem/TheBreadcrumbInstanzItem.vue";

import DataAPI from "./compositionAPI/DataAPI";

// @vue/component
export default {
  name: "TheBreadcrumbInstanz",
  components: {
    TheBreadcrumbInstanzItem,
  },
  setup() {
    const {
      hasStatuses,
      isComponentVisible,
      loadStatuses,
      statuses,
    } = DataAPI();

    loadStatuses();

    return {
      hasStatuses,
      isComponentVisible,
      statuses,
    };
  },
};
