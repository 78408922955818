import AButton from "aloha-vue/src/AButton/AButton";
import ALink from "aloha-vue/src/ALink/ALink";

import CookiesAPI from "./compositionAPI/CookiesAPI";

// @vue/component
export default {
  name: "TheCookiesMsg",
  components: {
    AButton,
    ALink,
  },
  setup() {
    const {
      checkCookies,
      isComponentVisible,
      saveCookies,
    } = CookiesAPI();

    checkCookies();

    return {
      isComponentVisible,
      saveCookies,
    };
  },
};
