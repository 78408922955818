export default `<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 18 18"
  height="18"
  width="18"
  version="1.1"
  class="bi-lock"
>
  <path
    d="m 8.66656,12.60972 v 0 c -0.38843,-0.13731 -0.66662,-0.50767 -0.66662,-0.9431 0,-0.55232 0.4477,-1.00002 1.00002,-1.00002 0.55232,0 1.00003,0.4477 1.00003,1.00002 0,0.4354 -0.27832,0.80585 -0.66663,0.9431 v 1.72954 c 0,0.17543 -0.14916,0.32727 -0.3334,0.32727 -0.17866,0 -0.3334,-0.14657 -0.3334,-0.32727 z M 3.99994,7.3333096 v 0 0 C 2.89477,7.3363096 2,8.2263796 2,9.3396896 V 12.66672 C 2,15.61224 4.39075,18 7.33271,18 h 3.33448 c 2.94517,0 5.3327,-2.39378 5.3327,-5.33328 V 9.3396896 c 0,-1.1062 -0.89391,-2.00314 -1.99994,-2.00638 v -2.33383 c 0,-2.7612 -2.24416,-4.99948004 -5,-4.99948004 -2.7614,0 -5.00001,2.23782004 -5.00001,4.99948004 z m 2.00003,0 v -2.33274 c 0,-1.66108 1.34315,-3.00056 2.99996,-3.00056 1.65344,0 2.99996,1.34342 2.99996,3.00056 v 2.33274 H 5.9999 Z"
  />
</svg>`;
