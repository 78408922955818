import {
  ref,
} from "vue";

const areFooterInternLinksVisible = ref(true);

export default function FooterAPI() {
  const showFooterInternLinksVisible = () => {
    areFooterInternLinksVisible.value = true;
  };

  const hideFooterInternLinksVisible = () => {
    areFooterInternLinksVisible.value = false;
  };

  return {
    areFooterInternLinksVisible,
    hideFooterInternLinksVisible,
    showFooterInternLinksVisible,
  };
}
