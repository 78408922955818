export default {
  persoenliche_daten: {
    route: "studierendePersoenlicheDaten",
    label: "Persönliche Daten prüfen",
    titleHtml: "<p>Prüfen Sie Ihre bei der Universität hinterlegten persönlichen Daten auf Korrektheit und Vollständigkeit.</p>",
  },
  studiendaten: {
    route: "studierendeStudiendaten",
    label: "Studiendaten prüfen",
    titleHtml: "<p>Prüfen Sie Ihre bei der Universität hinterlegten Studiendaten auf Korrektheit und Vollständigkeit.</p>",
  },
  besuchte_schulen: {
    route: "studierendeBesuchteSchulen",
    label: "Besuchte Schulen angeben",
    titleHtml: "<p>Geben Sie Schulen an, die Sie bereits besucht haben.</p>",
  },
  profildaten: {
    route: "studierendeProfildaten",
    label: "Profildaten bestätigen",
    titleHtml: "<p>Bestätigen Sie, dass die hinterlegten persönlichen Daten, Studiendaten, Lehrveranstaltungen und angegebenen besuchten Schulen korrekt und vollständig sind.</p>",
  },
  ortspunkt: {
    route: "studierendeOrtspunkt",
    label: "Ortspunkt wählen",
    titleHtml: "<p>Wählen Sie einen Ortspunkt, in dessen Nähe Sie gerne Ihr Praktikum durchführen würden.</p>",
  },
  merkmale: {
    route: "studierendeMerkmale",
    label: "Merkmale wählen",
    titleHtml: "<p>Wählen Sie Merkmale aus.</p>",
  },
  favoriten: {
    route: "studierendeFavoriten",
    label: "Schulen favorisieren",
    titleHtml: "<p>Favorisieren Sie Schulen, die Sie später zu Ihrer Wunschliste hinzufügen können.</p>",
  },
  wunschliste: {
    route: "studierendeWunschliste",
    label: "Wunschliste erstellen",
    titleHtml: "<p>Erstellen Sie aus Ihren favorisierten Schulen eine priorisierte Wunschliste.</p>",
  },
  finalisieren: {
    route: "studierendeAuswahlFinalisieren",
    label: "Auswahl finalisieren",
    titleHtml: "<p>Schließen Sie die Auswahl Ihrer bevorzugten Schulmerkmale, Schulen und des Ortspunktes ab.</p>",
  },
  anmeldung: {
    route: "studierendePraktikumAnmelden",
    label: "Praktikum anmelden",
    titleHtml: "<p>Bestätigen Sie hier verbindlich Ihre Anmeldung für das Praxissemester</p>",
  },
  zuweisung: {
    route: "studierendeZuweisung",
    label: ({ currentStudierendeInstanz } = {}) => currentStudierendeInstanz.i_bestaetigung_zuweisung ? "Zuweisung annehmen" : "Zuweisung anzeigen",
    titleHtml: "<p>Hier erhalten Sie Informationen über den aktuellen Status der Zuweisung Ihres Praktikumsplatzes.</p>",
    titleHtmlAufgabe: {
      COMPLETE: "Sie wurden einem Praktikumsplatz zugewiesen.",
      DISABLED: "Diese Informationen können Sie noch nicht einsehen.",
      PENDING: "Sie wurden noch keinem Praktikumsplatz zugewiesen.",
    },
  },
  praktikum: {
    route: "studierendePraktikumsdaten",
    label: "Praktikumsdaten anzeigen",
    titleHtml: "<p>Hier erhalten Sie Informationen über den Verlauf Ihres Praktikums.</p>",
    titleHtmlAufgabe: {
      COMPLETE: "Ihr Praktikum wurde als abgeschlossen eingetragen.",
      INCOMPLETE: "Ihr Praktikum wurde als abgebrochen eingetragen.",
      DISABLED: "Diese Informationen können Sie noch nicht einsehen.",
      PENDING: "Ihr Praktikum wurde noch nicht als beendet eingetragen.",
    },
  },
};
