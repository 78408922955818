import AIcon from "aloha-vue/src/AIcon/AIcon";
import ALink from "aloha-vue/src/ALink/ALink";

import RestAPI from "./compositionAPI/RestAPI";

// @vue/component
export default {
  name: "TheNavbarMessagesItem",
  components: {
    AIcon,
    ALink,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      absenderObj,
      messageDate,
    } = RestAPI(props);

    return {
      absenderObj,
      messageDate,
    };
  },
};
