import {
  createApp,
} from "vue";
import App from "./App/App.vue";
import router from "./Router/Router";

import allLanguages from "aloha-vue/src/i18n/allLanguages";
import AI18nPlugin from "aloha-vue/src/plugins/AI18nPlugin";
import AHttpPlugin from "aloha-vue/src/plugins/AHttpPlugin";
import APageTabTitlePlugin from "aloha-vue/src/plugins/APageTabTitlePlugin";
import AIconPlugin from "aloha-vue/src/plugins/AIconPlugin";
import AModalPlugin from "aloha-vue/src/plugins/AModalPlugin";
import AMobilePlugin from "aloha-vue/src/plugins/AMobilePlugin";
import ASelectPlugin from "aloha-vue/src/plugins/ASelectPlugin";
import mainIcons from "./mainIcons";
import mainErrorCallbacks from "./mainErrorCallbacks";
import {
  addErrorListenerForCodeSplitting,
} from "./global/functions/ErrorListenerForCodeSplitting";

const APP = createApp(App);
const TRANSLATIONS = {
  de: {
    ...allLanguages.de,
    _WIZARD_NEXT_: "Weiter",
    _WIZARD_PREVIOUS_: "Zurück",
  },
};
APP.use(AIconPlugin, {
  icons: mainIcons,
});
APP.use(AI18nPlugin, TRANSLATIONS, "de");
APP.use(APageTabTitlePlugin, "PVP");
APP.use(AMobilePlugin, {
  breakpoint: 991,
});
APP.use(AModalPlugin, {
  propsDefault: {
    closeButtonClass: "a_btn a_btn_link",
  },
});
APP.use(ASelectPlugin, {
  propsDefault: {
    menuWidthType: "by_content",
  },
});
APP.use(AHttpPlugin, {
  baseUrl: "/api/",
  axiosCreateOptions: {
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  },
  errorCallbacks: mainErrorCallbacks({ router }),
});

APP.use(router).mount("#app");

addErrorListenerForCodeSplitting();
