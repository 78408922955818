export default `<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 18 18"
  height="18"
  width="18"
  version="1.1"
>
  <path
    d="m 11.322515,13.30543 v 1.50644 c 0,0.1575 0.134663,0.28511 0.290286,0.28511 0.160349,0 0.290284,-0.13225 0.290284,-0.28511 v -1.50644 c 0.338313,-0.1196 0.58057,-0.44225 0.58057,-0.82146 0,-0.48108 -0.389982,-0.87096 -0.870942,-0.87096 -0.48096,0 -0.870942,0.38999 -0.870942,0.87096 0,0.37922 0.242362,0.70184 0.580569,0.82146 v 0 0 z M 7.2580724,4.3548498 c 0,-1.443 -1.1726961,-2.61286 -2.6128441,-2.61286 -1.442985,0 -2.6128442,1.17106 -2.6128442,2.61286 V 6.67697 c 0,0.47754 -0.3899821,0.8715 -0.870942,0.8715 C 0.67708514,7.54847 0.2905,7.15825 0.2905,6.67697 V 4.3544398 c 0,-2.40539 1.9497363,-4.35444 4.3547632,-4.35444 2.4001951,0 4.3547633,1.94959 4.3547633,4.35444 V 8.70973 h 6.9625295 c 0.965755,0 1.746944,0.78243 1.746944,1.74737 v 2.89769 C 17.7095,15.91508 15.630022,18 13.064925,18 H 10.160676 C 7.5983636,18 5.5161003,15.92025 5.5161003,13.35479 V 10.4571 c 0,-0.96963 0.7793512,-1.7447 1.7419021,-1.74737 V 4.3548098 Z"
  />
</svg>`;
