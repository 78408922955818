import ALink from "aloha-vue/src/ALink/ALink";

import FooterAPI from "./compositionAPI/FooterAPI";

// @vue/component
export default {
  name: "TheFooter",
  components: {
    ALink,
  },
  setup() {
    const {
      areFooterInternLinksVisible,
    } = FooterAPI();

    return {
      areFooterInternLinksVisible,
    };
  },
};
