const AusschlussmerkmaleDetails = () => import(/* webpackChunkName: "ausschlussmerkmaleDetails" */ "../../Views/Merkmale/AusschlussmerkmaleDetails/AusschlussmerkmaleDetails.vue");
const SchulmerkmaleDetails = () => import(/* webpackChunkName: "schulmerkmaleDetails" */ "../../Views/Merkmale/SchulmerkmaleDetails/SchulmerkmaleDetails.vue");
const ZuweisungsmerkmaleDetails = () => import(/* webpackChunkName: "zuweisungsmerkmaleDetails" */ "../../Views/Merkmale/ZuweisungsmerkmaleDetails/ZuweisungsmerkmaleDetails.vue");

export default [
  {
    path: "/merkmale/schulmerkmale/",
    name: "schulmerkmaleDetails",
    component: SchulmerkmaleDetails,
    meta: {
      permissions: [
        "backend.traits.edit",
      ],
    },
  },
  {
    path: "/merkmale/zuweisungsmerkmale/",
    name: "zuweisungsmerkmaleDetails",
    component: ZuweisungsmerkmaleDetails,
    meta: {
      permissions: [
        "backend.traits.edit",
      ],
    },
  },
  {
    path: "/merkmale/ausschlusssmerkmale/",
    name: "ausschlusssmerkmaleDetails",
    component: AusschlussmerkmaleDetails,
    meta: {
      permissions: [
        "backend.traits.edit",
      ],
    },
  },
];
