const AusleihenDetails = () => import(/* webpackChunkName: "ausleihenDetails" */ "../../Views/Organisationen/Ausleihen/AusleihenDetails/AusleihenDetails.vue");
const AusleihenList = () => import(/* webpackChunkName: "ausleihenList" */ "../../Views/Organisationen/Ausleihen/AusleihenList/AusleihenList.vue");
const BEZIRKSREGIERUNG_DETAILS = () => import(/* webpackChunkName: "bezirksregierungDetails" */ "../../Views/Organisationen/Bezirksregierung/BezirksregierungDetails/BezirksregierungDetails.vue");
const BEZIRKSREGIERUNG_LIST = () => import(/* webpackChunkName: "bezirksregierungList" */ "../../Views/Organisationen/Bezirksregierung/BezirksregierungList/BezirksregierungList.vue");
const ORGANISATIONSSTRUKTUR = () => import(/* webpackChunkName: "zfsLList" */ "../../Views/Organisationen/Organisationsstruktur/Organisationsstruktur.vue");
const OWN_SCHULE_DETAILS = () => import(/* webpackChunkName: "ownSchuleDetails" */ "../../Views/Organisationen/Schule/OwnSchuleDetails/OwnSchuleDetails.vue");
const OWN_SEMINAR_DETAILS = () => import(/* webpackChunkName: "ownSeminarDetails" */ "../../Views/Organisationen/Seminar/OwnSeminarDetails/OwnSeminarDetails.vue");
const REGION_DETAILS = () => import(/* webpackChunkName: "regionDetails" */ "../../Views/Organisationen/Region/RegionDetails/RegionDetails.vue");
const REGION_LIST = () => import(/* webpackChunkName: "regionList" */ "../../Views/Organisationen/Region/RegionList/RegionList.vue");
const SCHULE_DETAILS = () => import(/* webpackChunkName: "schuleDetails" */ "../../Views/Organisationen/Schule/SchuleDetails/SchuleDetails.vue");
const SCHULE_LIST = () => import(/* webpackChunkName: "schuleList" */ "../../Views/Organisationen/Schule/SchuleList/SchuleList.vue");
const SCHULSEMINAR_LIST = () => import(/* webpackChunkName: "schulseminarList" */ "../../Views/Organisationen/Schulseminar/SchulseminarList/SchulseminarList.vue");
const SEMINAR_DETAILS = () => import(/* webpackChunkName: "seminarDetails" */ "../../Views/Organisationen/Seminar/SeminarDetails/SeminarDetails.vue");
const SEMINAR_LIST = () => import(/* webpackChunkName: "seminarList" */ "../../Views/Organisationen/Seminar/SeminarList/SeminarList.vue");
const UNIVERSITAET_DETAILS = () => import(/* webpackChunkName "universitaetDetails" */ "../../Views/Organisationen/Universitaet/UniversitaetDetails/UniversitaetDetails.vue");
const UNIVERSITAET_LIST = () => import(/* webpackChunkName: "universitaetList" */ "../../Views/Organisationen/Universitaet/UniversitaetList/UniversitaetList.vue");
const ZFS_L_DETAILS = () => import(/* webpackChunkName: "zfsLDetails" */ "../../Views/Organisationen/ZfsL/ZfsLDetails/ZfsLDetails.vue");
const ZFS_L_LIST = () => import(/* webpackChunkName: "zfsLList" */ "../../Views/Organisationen/ZfsL/ZfsLList/ZfsLList.vue");

export default [
  {
    path: "/organisationen/uebersicht/",
    name: "organisationenPreview",
    component: ORGANISATIONSSTRUKTUR,
    meta: {
      permissions: [
        "backend.organisationtree.view",
      ],
    },
  },
  {
    path: "/organisationen/zfsl/",
    name: "zfslList",
    component: ZFS_L_LIST,
    meta: {
      permissions: [
        "backend.zfsl.view",
      ],
    },
  },
  {
    path: "/organisationen/zfsl/:id/",
    name: "zfslDetails",
    component: ZFS_L_DETAILS,
    meta: {
      permissions: [
        "backend.zfsl.view",
      ],
    },
  },
  {
    path: "/organisationen/seminar/",
    name: "seminarList",
    component: SEMINAR_LIST,
    meta: {
      permissions: [
        "backend.seminar.view",
      ],
      excludeRoles: [
        "uni",
      ],
    },
  },
  {
    path: "/organisationen/seminar-ansehen/",
    name: "seminarAnsehenList",
    component: SEMINAR_LIST,
    meta: {
      permissions: [
        "backend.seminar.view",
      ],
      roles: [
        "uni",
      ],
    },
  },
  {
    path: "/organisationen/seminar/:id/",
    name: "seminarDetails",
    component: SEMINAR_DETAILS,
    meta: {
      permissions: [
        "backend.seminar.view",
      ],
    },
  },
  {
    path: "/organisationen/schule/",
    name: "schuleList",
    component: SCHULE_LIST,
    meta: {
      permissions: [
        "backend.school.view",
      ],
      excludeRoles: [
        "seminar",
        "uni",
      ],
    },
  },
  {
    path: "/organisationen/schule-ansehen/",
    name: "schuleAnsehenList",
    component: SCHULE_LIST,
    meta: {
      permissions: [
        "backend.school.view",
      ],
      roles: [
        "seminar",
        "uni",
      ],
    },
  },
  {
    path: "/organisationen/schule/:id/",
    name: "schuleDetails",
    component: SCHULE_DETAILS,
    meta: {
      permissions: [
        "backend.school.view",
      ],
    },
  },
  {
    path: "/organisationen/ausbildungsregionen/",
    name: "regionList",
    component: REGION_LIST,
    meta: {
      permissions: [
        "backend.trainingregion.view",
      ],
    },
  },
  {
    path: "/organisationen/ausbildungsregionen/:id/",
    name: "regionDetails",
    component: REGION_DETAILS,
    meta: {
      permissions: [
        "backend.trainingregion.view",
      ],
    },
  },
  {
    path: "/organisationen/schulseminar/",
    name: "schulseminarList",
    component: SCHULSEMINAR_LIST,
    meta: {
      permissions: [
        "backend.seminarschool.view",
      ],
    },
  },
  {
    path: "/organisationen/bezirksregierung/",
    name: "bezirksregierungList",
    component: BEZIRKSREGIERUNG_LIST,
    meta: {
      permissions: [
        "backend.client.view",
      ],
    },
  },
  {
    path: "/organisationen/bezirksregierung/:id/",
    name: "bezirksregierungDetails",
    component: BEZIRKSREGIERUNG_DETAILS,
    meta: {
      permissions: [
        "backend.client.view",
      ],
    },
  },
  {
    path: "/organisationen/universitaet/",
    name: "universitaetList",
    component: UNIVERSITAET_LIST,
    meta: {
      permissions: [
        "backend.client.view",
      ],
    },
  },
  {
    path: "/organisationen/universitaet/:id/",
    name: "universitaetDetails",
    component: UNIVERSITAET_DETAILS,
    meta: {
      permissions: [
        "backend.client.view",
      ],
    },
  },
  {
    path: "/organisationen/own-schule/",
    name: "ownSchuleDetails",
    component: OWN_SCHULE_DETAILS,
    meta: {
      permissions: [
        "backend.ownschool.view"
      ],
    },
  },
  {
    path: "/organisationen/own-seminar/",
    name: "ownSeminarDetails",
    component: OWN_SEMINAR_DETAILS,
    meta: {
      permissions: [
        "backend.ownseminar.view",
      ],
    },
  },
  {
    path: "/organisationen/own-zfsl/",
    name: "ownZfslDetails",
    component: ZFS_L_DETAILS,
    meta: {
      permissions: [
        "backend.ownzfsl.view",
      ],
    },
    props: {
      isOwnZfsl: true,
    },
  },
  {
    path: "/organisationen/ausleihen/",
    name: "ausleihenList",
    component: AusleihenList,
    meta: {
      permissions: [
        "backend.ausleihanfragen.view",
      ],
    },
  },
  {
    path: "/organisationen/ausleihen/:id/",
    name: "ausleihenDetails",
    component: AusleihenDetails,
    meta: {
      permissions: [
        "backend.ausleihanfragen.view",
      ],
    },
  },
];
