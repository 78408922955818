const FaqDetails = () => import(/* webpackChunkName: "seminartypenList" */ "../../Views/FAQ/FaqDetails/FaqDetails.vue");
const NACHRICHTEN_EINGANG = () => import(/* webpackChunkName "nachrichtenEingang" */ "../../Views/Kommunikation/Nachrichten/NachrichtenEingang/NachrichtenEingang.vue");
const NACHRICHTEN_EINGANG_DETAILS = () => import(/* webpackChunkName "nachrichtenEingangDetails" */ "../../Views/Kommunikation/Nachrichten/NachrichtenEingang/NachrichtenEingangDetails/NachrichtenEingangDetails.vue");
const NACHRICHTEN_GESENDET = () => import(/* webpackChunkName "nachrichtenGesendet" */ "../../Views/Kommunikation/Nachrichten/NachrichtenGesendet/NachrichtenGesendet.vue");
const NACHRICHTEN_GESENDET_DETAILS = () => import(/* webpackChunkName "nachrichtenGesendetDetails" */ "../../Views/Kommunikation/Nachrichten/NachrichtenGesendet/NachrichtenGesendetDetails/NachrichtenGesendetDetails.vue");
const NACHRICHTEN_TEMPLATES_DETAILS = () => import(/* webpackChunkName: "nachrichtentemplatesDetails" */ "../../Views/Kommunikation/Nachrichtentemplates/NachrichtentemplatesDetails/NachrichtentemplatesDetails.vue");
const NACHRICHTEN_TEMPLATES_LIST = () => import(/* webpackChunkName: "nachrichtentemplatesList" */ "../../Views/Kommunikation/Nachrichtentemplates/NachrichtentemplatesList/NachrichtentemplatesList.vue");
const NEWS_ANZEIGEN = () => import(/* webpackChunkName: "newsAnzeigen" */ "../../Views/Kommunikation/News/NewsAnzeigen/NewsAnzeigen.vue");
const NEWS_VERWALTEN_DETAILS = () => import(/* webpackChunkName: "newsVerwaltenDetails" */ "../../Views/Kommunikation/News/NewsVerwaltenDetails/NewsVerwaltenDetails.vue");
const NEWS_VERWALTEN_LIST = () => import(/* webpackChunkName: "newsVerwaltenList" */ "../../Views/Kommunikation/News/NewsVerwaltenList/NewsVerwaltenList.vue");


export default [
  {
    path: "/kommunikation/news/verwalten/",
    name: "newsVerwaltenList",
    component: NEWS_VERWALTEN_LIST,
    meta: {
      permissions: [
        "backend.news.view",
      ],
    },
  },
  {
    path: "/kommunikation/news/verwalten/:id/",
    name: "newsVerwaltenDetails",
    component: NEWS_VERWALTEN_DETAILS,
    meta: {
      permissions: [
        "backend.news.view",
      ],
    },
  },
  {
    path: "/kommunikation/news/",
    name: "newsView",
    component: NEWS_ANZEIGEN,
  },
  {
    path: "/kommunikation/nachrichtentemplates/",
    name: "nachrichtentemplatesList",
    component: NACHRICHTEN_TEMPLATES_LIST,
    meta: {
      permissions: [
        "backend.message_templates.view",
      ],
    },
  },
  {
    path: "/kommunikation/nachrichtentemplates/:id/",
    name: "nachrichtentemplatesDetails",
    component: NACHRICHTEN_TEMPLATES_DETAILS,
    meta: {
      permissions: [
        "backend.message_templates.view",
      ],
    },
  },
  {
    path: "/kommunikation/nachrichten/eingang/",
    name: "nachrichtenEingang",
    component: NACHRICHTEN_EINGANG,
    meta: {
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/kommunikation/nachrichten/gesendet/",
    name: "nachrichtenGesendet",
    component: NACHRICHTEN_GESENDET,
    meta: {
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/kommunikation/nachrichten/eingang/:id/",
    name: "nachrichtenEingangDetails",
    component: NACHRICHTEN_EINGANG_DETAILS,
    meta: {
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/kommunikation/nachrichten/gesendet/:id/",
    name: "nachrichtenGesendetDetails",
    component: NACHRICHTEN_GESENDET_DETAILS,
    meta: {
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/faq/",
    name: "faqDetails",
    component: FaqDetails,
    props: {
      readonly: true,
    },
    meta: {
      loginNoMatter: true,
    },
  },
];
