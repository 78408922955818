import TheNavbarMessages from "./TheNavbarMessages/TheNavbarMessages.vue";
import TheNavbarNews from "./TheNavbarNews/TheNavbarNews.vue";
import TheNavbarUserMenu from "./TheNavbarUserMenu/TheNavbarUserMenu.vue";

import AMobileAPI from "aloha-vue/src/compositionAPI/AMobileAPI";
import BrandingAPI from "../../global/compositionAPI/BrandingAPI";
import TextHeaderAPI from "./compositionAPI/TextHeaderAPI";
import UserAPI from "../../global/compositionAPI/UserAPI";

// @vue/component
export default {
  name: "TheNavbar",
  components: {
    TheNavbarMessages,
    TheNavbarNews,
    TheNavbarUserMenu,
  },
  setup() {
    const {
      branding,
    } = BrandingAPI();

    const {
      isAuthenticated,
      isMultipleUsersSelect,
      isPasswordChangeRequired,
    } = UserAPI();

    const {
      isMobileWidth,
    } = AMobileAPI();

    const {
      textHeader,
    } = TextHeaderAPI();

    return {
      branding,
      isAuthenticated,
      isMobileWidth,
      isMultipleUsersSelect,
      isPasswordChangeRequired,
      textHeader,
    };
  },
};
