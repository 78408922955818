import {
  ref,
} from "vue";

import AModalConfirm from "aloha-vue/src/AModalConfirm/AModalConfirm";
import ANotification from "aloha-vue/src/ANotification/ANotification";
import PuxTaskLog from "../global/components/PuxTaskLog/PuxTaskLog.vue";
import TheBanner from "../SingleUsageComponents/TheBanner/TheBanner.vue";
import TheBreadcrumb from "../SingleUsageComponents/TheBreadcrumb/TheBreadcrumb.vue";
import TheBreadcrumbInstanz from "../SingleUsageComponents/TheBreadcrumbInstanz/TheBreadcrumbInstanz.vue";
import TheCookiesMsg from "../SingleUsageComponents/TheCookiesMsg/TheCookiesMsg.vue";
import TheFooter from "../SingleUsageComponents/TheFooter/TheFooter.vue";
import TheMenu from "../SingleUsageComponents/TheMenu/TheMenu.vue";
import TheNavbar from "../SingleUsageComponents/TheNavbar/TheNavbar.vue";
import TheScrollTop from "../SingleUsageComponents/TheScrollTop/TheScrollTop.vue";

import AConfirmAPI from "aloha-vue/src/compositionAPI/AConfirmAPI";
import DynamischeTexteAPI from "../global/compositionAPI/DynamischeTexteAPI";
import HilfethemenAPI from "../global/compositionAPI/HilfethemenAPI";
import UserAPI from "../global/compositionAPI/UserAPI";

// @vue/component
export default {
  name: "App",
  components: {
    AModalConfirm,
    ANotification,
    PuxTaskLog,
    TheBanner,
    TheBreadcrumb,
    TheBreadcrumbInstanz,
    TheCookiesMsg,
    TheFooter,
    TheMenu,
    TheNavbar,
    TheScrollTop,
  },
  setup() {
    const {
      getUser,
      isAuthenticated,
      isMultipleUsersSelect,
      isPasswordChangeRequired,
    } = UserAPI();

    const {
      loadHilfethemen,
    } = HilfethemenAPI();

    const {
      loadDynamischeTexte,
    } = DynamischeTexteAPI();

    const {
      confirmOptions,
    } = AConfirmAPI();

    const loading = ref(true);

    getUser().then(
      () => {
        loadHilfethemen();
        loadDynamischeTexte();
      },
      () => {},
    ).finally(() => {
      loading.value = false;
    });

    return {
      confirmOptions,
      isAuthenticated,
      isMultipleUsersSelect,
      isPasswordChangeRequired,
      loading,
    };
  },
};
