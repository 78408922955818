import AAlert from "aloha-vue/src/AAlert/AAlert";

import ASafeHtml from "aloha-vue/src/directives/ASafeHtml";

import AMobileAPI from "aloha-vue/src/compositionAPI/AMobileAPI";
import TextAPI from "./compositionAPI/TextAPI";
import UserAPI from "../../global/compositionAPI/UserAPI";

// @vue/component
export default {
  name: "TheBanner",
  components: {
    AAlert,
  },
  directives: {
    ASafeHtml,
  },
  setup() {
    const {
      bannerText,
    } = TextAPI();

    const {
      isAuthenticated,
    } = UserAPI();

    const {
      isMobileWidth,
    } = AMobileAPI();

    return {
      bannerText,
      isAuthenticated,
      isMobileWidth,
    };
  },
};
