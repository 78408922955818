import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

import EventBus from "../../../../global/functions/EventBus";

export default function DataAPI() {
  const loading = ref(true);
  const newsCount = ref(0);
  const newsPreview = ref([]);

  const {
    getHttp,
  } = AHttpAPI();

  const loadNews = () => {
    getHttp({
      url: "news_ro/",
      urlParams: {
        limit: 2,
      }
    }).then(
      response => {
        newsPreview.value = response.results;
        newsCount.value = response.count;
        loading.value = false;
      }
    );
  };

  const initEventBus = () => {
    EventBus.$on("updateNavbarNews", loadNews);
  };

  const destroyEventBus = () => {
    EventBus.$off("updateNavbarNews", loadNews);
  };

  return {
    destroyEventBus,
    initEventBus,
    loading,
    loadNews,
    newsCount,
    newsPreview,
  };
}
