import {
  addNotification,
} from "aloha-vue/src/compositionAPI/ANotificationAPI";
import {
  me,
  setMe,
} from "./global/compositionAPI/UserAPI";
import {
  abortHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  get, 
  startsWith,
} from "lodash-es";

export default function({ router }) {
  return {
    401: ({ showError, error }) => {
      const ERROR_DETAIL = get(error, "data.detail");
      if (ERROR_DETAIL) {
        addNotification({
          type: "danger",
          text: ERROR_DETAIL,
        });
      }
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const authentifizierungsart = get(me.value, "authentifizierungsart");
      setMe();
      if (authentifizierungsart === 3) {
        router.push({
          name: "loginAdmin",
        });
      } else {
        router.push({
          name: "login",
        });
      }
      return showError;
    },
    403: ({ showError, error }) => {
      if (startsWith(error?.data?.detail, "CSRF Failed")) {
        router.push({ name: "logoutPage" });
        return showError;
      }
      addNotification({
        text: "<p><strong>Unzureichende Berechtigung</strong></p><p>Sie haben nicht die notwendigen Berechtigungen, um diese Aktion auszuführen</p>",
        type: "error",
        timeout: 0,
      });
      return true;
    },
    404: ({ showError, error }) => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      const ERROR_ROUTE_NAME = "errors404";
      redirectOnError({
        error,
        showError,
        redirectTo: ERROR_ROUTE_NAME,
        $redirect: router.push,
        currentRoute: router.currentRoute.value,
        overrideNextUrl: window.location.href,
      });
      return showError;
    },
    503: () => {
      abortHttp({
        all: true,
        excludeAbortGroup: ["global"],
      });
      router.push({
        name: "wartungPage",
      });
      return false;
    },
    all: ({ error, showError }) => {
      if (error.status >= 500 && error.status <= 599) {
        abortHttp({
          all: true,
          excludeAbortGroup: ["global"],
        });
        const ERROR_ROUTE_NAME = "errors50*";
        redirectOnError({
          error,
          showError,
          redirectTo: ERROR_ROUTE_NAME,
          $redirect: router.push,
          currentRoute: router.currentRoute.value,
        });
        return showError;
      } else if (error.status > 400 && error.status <= 499) {
        abortHttp({
          all: true,
          excludeAbortGroup: ["global"],
        });
        const ERROR_ROUTE_NAME = "errors40*";
        redirectOnError({
          error,
          showError,
          redirectTo: ERROR_ROUTE_NAME,
          $redirect: router.push,
          currentRoute: router.currentRoute.value,
        });
        return showError;
      }
      return true;
    },
  };
}

function makeParams({ currentRoute, overrideNextUrl }) {
  const PARAMS = {};
  const EXISTING_NEXT_PARAM = get(currentRoute, "query.next");
  if (!EXISTING_NEXT_PARAM) {
    if (overrideNextUrl) {
      PARAMS.next = overrideNextUrl;
    } else {
      PARAMS.next = currentRoute.fullPath;
    }
  }
  return PARAMS;
}

function redirectOnError({ showError, redirectTo, $redirect, currentRoute, overrideNextUrl }) {
  const CURRENT_ROUTE_NAME = currentRoute?.name;
  if (redirectTo === CURRENT_ROUTE_NAME) {
    return;
  }
  const PARAMS = makeParams({ currentRoute, overrideNextUrl });
  $redirect({
    name: redirectTo,
    query: PARAMS,
  });
  return showError;
}
