import {
  computed,
  getCurrentInstance,
} from "vue";

import DataInstanzenAPI, {
  instanzenData,
} from "./DataInstanzenAPI";
import DataDefault from "../DataDefault";
import {
  studierendeData,
} from "./DataStudierendeAPI";

import {
  isRouteVisible,
} from "../../../Router/RouterUtils";
import {
  cloneDeep,
  forEach,
} from "lodash-es";

const menuData = computed(() => {
  const MENU_DATA = cloneDeep(DataDefault);
  if (studierendeData.value.length) {
    MENU_DATA.splice(1, 0, ...studierendeData.value);
  }
  if (instanzenData.value.length) {
    MENU_DATA.splice(1, 0, ...instanzenData.value);
  }
  return MENU_DATA;
});

export const menuDataKeyById = computed(() => {
  let menuDataKeyByIdLocal = {};
  menuDataKeyByIdLocal = getCurrentMenuDataKeyById({ menuItems: menuData.value, menuDataKeyByIdLocal });
  return menuDataKeyByIdLocal;
});

export default function DataAPI() {
  const APP = getCurrentInstance();
  const $router = APP.appContext.config.globalProperties.$router;

  const {
    dataInstanzen,
    isLoadingInstanzen,
    modelInstanzen,
    updateModelInstanzen,
  } = DataInstanzenAPI();

  const setRoutesObj = () => {
    const routesObj = {};
    forEach($router.options.routes, route => {
      routesObj[route.name] = route;
    });
    return routesObj;
  };

  const routesObj = setRoutesObj();

  const getMenuChildrenFiltered = ({ children, menuDataNew }) => {
    if (children &&
      children.length) {
      forEach(children, child => {
        if (!child.to) {
          if (child.children && child.children.length) {
            child.children = getMenuChildrenFiltered({ children: child.children, menuDataNew: [] });
            if (child.children && child.children.length) {
              menuDataNew.push(child);
              return;
            }
          }
        }
        if (child.slot) {
          menuDataNew.push(child);
        } else if (child.to) {
          const routesObjItem = routesObj[child.to.name];
          if (routesObjItem && isRouteVisible({
            to: routesObjItem,
          })) {
            menuDataNew.push(child);
          }
        }
      });
    }
    return menuDataNew;
  };

  const dataFiltered = computed(() => {
    const menuDataNew = [];
    return getMenuChildrenFiltered({ children: cloneDeep(menuData.value), menuDataNew });
  });

  return {
    dataFiltered,
    dataInstanzen,
    isLoadingInstanzen,
    modelInstanzen,
    updateModelInstanzen,
  };
}

function getCurrentMenuDataKeyById({ menuItems, menuDataKeyByIdLocal }) {
  if (menuItems && menuItems.length) {
    forEach(menuItems, item => {
      menuDataKeyByIdLocal[item.id] = item;
      if (item.children && item.children.length) {
        menuDataKeyByIdLocal = getCurrentMenuDataKeyById({ menuItems: item.children, menuDataKeyByIdLocal });
      }
    });
  }
  return menuDataKeyByIdLocal;
}
