const DownloadsInstanzList = () => import(/* webpackChunkName: "downloadsInstanzList" */ "../../Views/UniInstanzen/Downloads/DownloadsInstanzList/DownloadsInstanzList.vue");
const DynamischeDokumenteInstanzDetails = () => import(/* webpackChunkName: "dynamischeDokumenteInstanzDetails" */ "../../Views/UniInstanzen/Dokumente/DynamischeDokumente/DynamischeDokumenteInstanzDetails/DynamischeDokumenteInstanzDetails.vue");
const DynamischeDokumenteInstanzList = () => import(/* webpackChunkName: "dynamischeDokumenteInstanzList" */ "../../Views/UniInstanzen/Dokumente/DynamischeDokumente/DynamischeDokumenteInstanzList/DynamischeDokumenteInstanzList.vue");
const INSTANZ_REGIONALKLASSEN_LIST = () => import(/* webpackChunkName: "regionalklassenVerwaltenList" */ "../../Views/UniInstanzen/Universitaet/Regionalklassen/RegionalklassenList/RegionalklassenList.vue");
const INSTANZ_SCHULE_DETAILS = () => import(/* webpackChunkName: "instanzSchuleDetails" */ "../../Views/UniInstanzen/Organisationen/Schule/SchuleInstanzDetails/SchuleInstanzDetails.vue");
const INSTANZ_SCHULE_LIST = () => import(/* webpackChunkName: "instanzSchuleList" */ "../../Views/UniInstanzen/Organisationen/Schule/SchuleInstanzList/SchuleInstanzList.vue");
const INSTANZ_STUDIENGANG_DETAILS = () => import(/* webpackChunkName: "studiengangDetails" */ "../../Views/UniInstanzen/Universitaet/Studiengaenge/InstanzStudiengangDetails/InstanzStudiengangDetails.vue");
const INSTANZ_STUDIENGANG_LIST = () => import(/* webpackChunkName: "instanzStudiengangList" */ "../../Views/UniInstanzen/Universitaet/Studiengaenge/InstanzStudiengangList/InstanzStudiengangList.vue");
const INSTANZ_STUDIENRICHTUNG_DETAILS = () => import(/* webpackChunkName: "instanzStudienrichtungDetails" */ "../../Views/UniInstanzen/Universitaet/Studienrichungen/InstanzStudienrichtungDetails/InstanzStudienrichtungDetails.vue");
const INSTANZ_STUDIENRICHTUNG_LIST = () => import(/* webpackChunkName: "instanzStudienrichtungList" */ "../../Views/UniInstanzen/Universitaet/Studienrichungen/InstanzStudienrichtungList/InstanzStudienrichtungList.vue");
const INSTANZ_STUDIENRICHTUNGSMERKMALE = () => import(/* webpackChunkName: "studienrichtungsmerkmaleVerwalten" */ "../../Views/UniInstanzen/Universitaet/Studienrichtungsmerkmale/Studienrichtungsmerkmale.vue");
const INSTANZ_UNISCHULFACH_DETAILS = () => import(/* webpackChunkName: "universitaetsschulfachDetails" */ "../../Views/UniInstanzen/Universitaet/Universitaetsschulfaecher/InstanzUniversitaetsschulfachDetails/InstanzUniversitaetsschulfachDetails.vue");
const INSTANZ_UNISCHULFACH_LIST = () => import(/* webpackChunkName: "instanzUniversitaetsschulfachList" */ "../../Views/UniInstanzen/Universitaet/Universitaetsschulfaecher/InstanzUniversitaetsschulfachList/InstanzUniversitaetsschulfachList.vue");
const INSTANZ_VERTEILRUNDEN_DETAILS = () => import(/* webpackChunkName: "instanzVerteilrundenDetails" */ "../../Views/UniInstanzen/Verteilung/Verteilrunden/VerteilrundenDetails/VerteilrundenDetails.vue");
const INSTANZ_VERTEILRUNDEN_LIST = () => import(/* webpackChunkName: "instanzVerteilrundenList" */ "../../Views/UniInstanzen/Verteilung/Verteilrunden/VerteilrundenList/VerteilrundenList.vue");
const INSTANZ_VERTEILUNGSGUETE_LIST = () => import(/* webpackChunkName: "instanzVerteilungsgueteList" */ "../../Views/UniInstanzen/Verteilung/Verteilungsguete/Verteilungsguete.vue");
const InstanzenUebersicht = () => import(/* webpackChunkName: "instanzenUebersicht" */ "../../Views/UniInstanzen/Instanzen/InstanzenUebersicht/InstanzenUebersicht.vue");
const InstanzFristenDetails = () => import(/* webpackChunkName: "instanzFristenDetails" */ "../../Views/UniInstanzen/Instanzen/InstanzFristenDetails/InstanzFristenDetails.vue");
const InstanzKonfigurieren = () => import(/* webpackChunkName: "instanzKonfigurieren" */ "../../Views/UniInstanzen/Instanzen/InstanzKonfigurieren/InstanzKonfigurieren.vue");
const Kapazitaetspruefung = () => import(/* webpackChunkName: "kapazitaetspruefung" */ "../../Views/UniInstanzen/Verteilung/Kapazitaetspruefung/Kapazitaetspruefung.vue");
const NachrichtenEingangInstanzDetails = () => import(/* webpackChunkName: "nachrichtenEingangInstanzDetails" */ "../../Views/UniInstanzen/Kommunikation/Nachrichten/NachrichtenEingangInstanzDetails/NachrichtenEingangInstanzDetails.vue");
const NachrichtenEingangInstanzList = () => import(/* webpackChunkName: "nachrichtenEingangInstanzList" */ "../../Views/UniInstanzen/Kommunikation/Nachrichten/NachrichtenEingangInstanzList/NachrichtenEingangInstanzList.vue");
const NachrichtenGesendetInstanzDetails = () => import(/* webpackChunkName: "nachrichtenGesendetInstanzDetails" */ "../../Views/UniInstanzen/Kommunikation/Nachrichten/NachrichtenGesendetInstanzDetails/NachrichtenGesendetInstanzDetails.vue");
const NachrichtenGesendetInstanzList = () => import(/* webpackChunkName: "nachrichtenGesendetInstanzList" */ "../../Views/UniInstanzen/Kommunikation/Nachrichten/NachrichtenGesendetInstanzList/NachrichtenGesendetInstanzList.vue");
const NachrichtentemplatesInstanzDetails = () => import(/* webpackChunkName: "nachrichtentemplatesInstanzDetails" */ "../../Views/UniInstanzen/Kommunikation/Nachrichtentemplates/NachrichtentemplatesInstanzDetails/NachrichtentemplatesInstanzDetails.vue");
const NachrichtentemplatesInstanzList = () => import(/* webpackChunkName: "nachrichtentemplatesInstanzList" */ "../../Views/UniInstanzen/Kommunikation/Nachrichtentemplates/NachrichtentemplatesInstanzList/NachrichtentemplatesInstanzList.vue");
const SchulseitigeMerkmale = () => import(/* webpackChunkName: "schulseitigeMerkmale" */ "../../Views/UniInstanzen/Universitaet/SchulseitigeMerkmale/SchulseitigeMerkmale.vue");
const SeminarInstanzDetails = () => import(/* webpackChunkName: "seminarInstanzDetails" */ "../../Views/UniInstanzen/Organisationen/Seminar/SeminarInstanzDetails/SeminarInstanzDetails.vue");
const SeminarInstanzList = () => import(/* webpackChunkName: "seminarInstanzList" */ "../../Views/UniInstanzen/Organisationen/Seminar/SeminarInstanzList/SeminarInstanzList.vue");
const StatischeDokumenteInstanzDetails = () => import(/* webpackChunkName: "statischeDokumenteInstanzDetails" */ "../../Views/UniInstanzen/Dokumente/StatischeDokumente/StatischeDokumenteInstanzDetails/StatischeDokumenteInstanzDetails.vue");
const StatischeDokumenteInstanzList = () => import(/* webpackChunkName: "statischeDokumenteInstanzList" */ "../../Views/UniInstanzen/Dokumente/StatischeDokumente/StatischeDokumenteInstanzList/StatischeDokumenteInstanzList.vue");
const STUDIERENDE_AN_SCHULEN_LIST = () => import(/* webpackChunkName: "studierendeAnSchulenList" */ "../../Views/UniInstanzen/Studierende/StudierendeAnSchulenInstanz/StudierendeAnSchulenInstanzList/StudierendeAnSchulenInstanzList.vue");
const STUDIERENDE_AN_SEMINAREN_LIST = () => import(/* webpackChunkName: "studierendeAnSeminarenList" */ "../../Views/UniInstanzen/Studierende/StudierendeAnSeminaren/StudierendeAnSeminarenList/StudierendeAnSeminarenList.vue");
const STUDIERENDE_JE_SCHUL_SEMINAR_KOMBI_LIST = () => import(/* webpackChunkName: "studierendeJeSchulSeminarKombiList" */ "../../Views/UniInstanzen/Studierende/StudierendeJeSchulSeminarKombiInstanz/StudierendeJeSchulSeminarKombiInstanzList/StudierendeJeSchulSeminarKombiInstanzList.vue");
const StudierendeInstanzDetails = () => import(/* webpackChunkName: "studierendeInstanzDetails" */ "../../Views/UniInstanzen/Studierende/StudierendeVerwalten/StudierendeInstanzDetails/StudierendeInstanzDetails.vue");
const StudierendeInstanzList = () => import(/* webpackChunkName: "studierendeInstanzList" */ "../../Views/UniInstanzen/Studierende/StudierendeVerwalten/StudierendeInstanzList/StudierendeInstanzList.vue");
const StudierendenMerkmale = () => import(/* webpackChunkName: "studierendenMerkmale" */ "../../Views/UniInstanzen/Studierende/StudierendenMerkmale/StudierendenMerkmale.vue");
const ZfslInstanzDetails = () => import(/* webpackChunkName: "zfslInstanzDetails" */ "../../Views/UniInstanzen/Organisationen/ZfsL/ZfslInstanzDetails/ZfslInstanzDetails.vue");
const ZfslInstanzList = () => import(/* webpackChunkName: "zfslInstanzList" */ "../../Views/UniInstanzen/Organisationen/ZfsL/ZfslInstanzList/ZfslInstanzList.vue");

export default [
  // UNI Instanzen
  //    Ausgewählte Instanz
  {
    path: "/instanz/uebersicht/",
    name: "instanzdatenUebersicht",
    component: InstanzenUebersicht,
    meta: {
      instanz: true,
      permissions: [
        "backend.instance.view_selected",
      ],
    },
  },
  {
    path: "/instanz/fristen/",
    name: "instanzdatenFristen",
    component: InstanzFristenDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.instance.deadlines_view",
      ],
    },
  },
  {
    path: "/instanz/instanz-konfigurieren/",
    name: "instanzdatenInstanzKonfigurieren",
    component: InstanzKonfigurieren,
    meta: {
      instanz: true,
      permissions: [
        "backend.instance.config_view",
      ],
    },
  },
  //    Ausgewählte Instanz /
  //    Universität
  {
    path: "/instanz/studiengang/",
    name: "instanzStudiengangList",
    component: INSTANZ_STUDIENGANG_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.universitycourse.view",
      ],
    },
  },
  {
    path: "/instanz/studiengang/:id/",
    name: "instanzStudiengangDetails",
    component: INSTANZ_STUDIENGANG_DETAILS,
    meta: {
      instanz: true,
      permissions: [
        "backend.universitycourse.view",
      ],
    },
  },
  {
    path: "/instanz/studienrichtung/",
    name: "instanzStudienrichtungList",
    component: INSTANZ_STUDIENRICHTUNG_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.universitykeyaspect.view",
      ],
    },
  },
  {
    path: "/instanz/studienrichtung/:id/",
    name: "instanzStudienrichtungDetails",
    component: INSTANZ_STUDIENRICHTUNG_DETAILS,
    meta: {
      instanz: true,
      permissions: [
        "backend.universitykeyaspect.view",
      ],
    },
  },
  {
    path: "/instanz/universitaetsschulfach/",
    name: "instanzUniversitaetsschulfachList",
    component: INSTANZ_UNISCHULFACH_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.universitysubject.view",
      ],
    },
  },
  {
    path: "/instanz/universitaetsschulfach/:id/",
    name: "instanzUniversitaetsschulfachDetails",
    component: INSTANZ_UNISCHULFACH_DETAILS,
    meta: {
      instanz: true,
      permissions: [
        "backend.universitysubject.view",
      ],
    },
  },
  {
    path: "/instanz/studienrichtungsmerkmale-verwalten/",
    name: "studienrichtungsmerkmaleVerwalten",
    component: INSTANZ_STUDIENRICHTUNGSMERKMALE,
    meta: {
      instanz: true,
      permissions: [
        "backend.instancetraits.view",
      ],
    },
  },
  {
    path: "/instanz/schulseitige-merkmale-verwalten/",
    name: "schulseitigeMerkmaleVerwalten",
    component: SchulseitigeMerkmale,
    meta: {
      instanz: true,
      permissions: [
        "backend.instancetraits.view",
      ],
    },
  },
  {
    path: "/instanz/regionalklassen-verwalten/",
    name: "regionalklassenVerwaltenList",
    component: INSTANZ_REGIONALKLASSEN_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.regionclass.view",
      ],
    },
  },
  //    Universität /
  //    Organisationen
  {
    path: "/instanz/zfsl/",
    name: "instanzZfslList",
    component: ZfslInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "backend.instancezfsl.view",
      ],
    },
  },
  {
    path: "/instanz/zfsl/:id/",
    name: "instanzZfslDetails",
    component: ZfslInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.instancezfsl.view",
      ],
    },
  },
  {
    path: "/instanz/seminar/",
    name: "instanzSeminarList",
    component: SeminarInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "backend.instanceseminar.view",
      ],
    },
  },
  {
    path: "/instanz/seminar/:id/",
    name: "instanzSeminarDetails",
    component: SeminarInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.instanceseminar.view",
      ],
    },
  },
  {
    path: "/instanz/schule/",
    name: "instanzSchuleList",
    component: INSTANZ_SCHULE_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.instanceschool.view",
      ],
    },
  },
  {
    path: "/instanz/schule/:id/",
    name: "instanzSchuleDetails",
    component: INSTANZ_SCHULE_DETAILS,
    meta: {
      instanz: true,
      permissions: [
        "backend.instanceschool.view",
      ],
    },
  },
  //    Organisationen /
  //    Downloads /
  {
    path: "/instanz/downloads/",
    name: "instanzDownloadsList",
    component: DownloadsInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "downloads.view",
      ],
    },
  },
  //    Downloads /
  //    Dokumente
  {
    path: "/instanz/templates/",
    name: "instanzTemplatesList",
    component: DynamischeDokumenteInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "backend.templates.view",
      ],
    },
  },
  {
    path: "/instanz/templates/:id/",
    name: "instanzTemplatesDetails",
    component: DynamischeDokumenteInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.templates.view",
      ],
    },
  },
  {
    path: "/instanz/statische-dokumente/",
    name: "instanzStatischeDokumenteList",
    component: StatischeDokumenteInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "backend.static_docs.view",
      ],
    },
  },
  {
    path: "/instanz/statische-dokumente/:id/",
    name: "instanzStatischeDokumenteDetails",
    component: StatischeDokumenteInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.static_docs.view",
      ],
    },
  },
  //    Dokumente /
  //    Studierende
  {
    path: "/instanz/studierende-verwalten/",
    name: "instanzStudierendeVerwaltenList",
    component: StudierendeInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "backend.student.view",
      ],
    },
  },
  {
    path: "/instanz/studierende-verwalten/:id/",
    name: "instanzStudierendeVerwaltenDetails",
    component: StudierendeInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.student.view",
      ],
    },
  },
  {
    path: "/instanz/studierendenmerkmale-verwalten/",
    name: "instanzStudierendenmerkmaleVerwalten",
    component: StudierendenMerkmale,
    meta: {
      instanz: true,
      permissions: [
        "backend.instancetraits.view",
      ],
    },
  },
  {
    path: "/instanz/studierende-an-schulen/",
    name: "instanzStudierendeAnSchulenList",
    component: STUDIERENDE_AN_SCHULEN_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.student.view",
      ],
    },
  },
  {
    path: "/instanz/studierende-an-seminaren/",
    name: "instanzStudierendeAnSeminarenList",
    component: STUDIERENDE_AN_SEMINAREN_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.student.view",
      ],
    },
  },
  {
    path: "/instanz/studierende-schul-seminar-zuordnung/",
    name: "instanzStudierendeSchulSeminarZuordnungList",
    component: STUDIERENDE_JE_SCHUL_SEMINAR_KOMBI_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.student.view",
      ],
    },
  },
  //    Studierende /
  //    Verteilung
  {
    path: "/instanz/kapazitaetspruefung/",
    name: "instanzKapazitaetspruefung",
    component: Kapazitaetspruefung,
    meta: {
      instanz: true,
      instanzPermissions: true,
      permissions: [
        "backend.optimisation.capacitycheck_view",
      ],
    },
  },
  {
    path: "/instanz/verteilrunden/",
    name: "instanzVerteilrundenList",
    component: INSTANZ_VERTEILRUNDEN_LIST,
    meta: {
      instanz: true,
      instanzPermissions: true,
      permissions: [
        "backend.optimisation.probleminstance_view",
      ],
    },
  },
  {
    path: "/instanz/verteilrunden/:id",
    name: "instanzVerteilrundenDetails",
    component: INSTANZ_VERTEILRUNDEN_DETAILS,
    meta: {
      instanz: true,
      instanzPermissions: true,
      permissions: [
        "backend.optimisation.probleminstance_view",
      ],
    },
  },
  {
    path: "/instanz/verteilungsguete/",
    name: "instanzVerteilungsgueteList",
    component: INSTANZ_VERTEILUNGSGUETE_LIST,
    meta: {
      instanz: true,
      permissions: [
        "backend.optimisation.probleminstancequality_view",
      ],
    },
  },
  //    Verteilung /
  //    Kommunikation
  {
    path: "/instanz/nachrichtentemplates/",
    name: "instanzNachrichtentemplatesList",
    component: NachrichtentemplatesInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "backend.message_templates.view",
      ],
    },
  },
  {
    path: "/instanz/nachrichtentemplates/:id/",
    name: "instanzNachrichtentemplatesDetails",
    component: NachrichtentemplatesInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "backend.message_templates.view",
      ],
    },
  },
  {
    path: "/instanz/nachrichten-eingang/",
    name: "instanzNachrichtenEingangList",
    component: NachrichtenEingangInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/instanz/nachrichten-eingang/:id/",
    name: "instanzNachrichtenEingangDetails",
    component: NachrichtenEingangInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/instanz/nachrichten-gesendet/",
    name: "instanzNachrichtenGesendetList",
    component: NachrichtenGesendetInstanzList,
    meta: {
      instanz: true,
      permissions: [
        "messages.view",
      ],
    },
  },
  {
    path: "/instanz/nachrichten-gesendet/:id/",
    name: "instanzNachrichtenGesendetDetails",
    component: NachrichtenGesendetInstanzDetails,
    meta: {
      instanz: true,
      permissions: [
        "messages.view",
      ],
    },
  },
  //    Kommunikation /
  // UNI Instanzen /
];
