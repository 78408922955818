import {
  computed,
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import PermissionAPI from "../../../../global/compositionAPI/PermissionAPI";
import UserAPI from "../../../../global/compositionAPI/UserAPI";

import EventBus from "../../../../global/functions/EventBus";

export default function DataAPI() {
  const loading = ref(true);
  const messagesCount = ref(0);
  const messagesPreview = ref([]);

  const {
    getHttp,
  } = AHttpAPI();

  const {
    checkPermissionsSync,
  } = PermissionAPI();

  const {
    me,
  } = UserAPI();

  const hasPermissionMessagesView = computed(() => {
    return checkPermissionsSync({
      permission: "messages.view",
    });
  });

  const loadMessages = () => {
    if (!hasPermissionMessagesView.value) {
      return;
    }
    getHttp({
      url: "nachrichten/posteingang/",
      urlParams: {
        limit: 2,
        ordering: "-crdate",
        meg_gelesen: false,
        empfaenger: me.value.pk,
      }
    }).then(
      response => {
        messagesPreview.value = response.results;
        messagesCount.value = response.count;
        loading.value = false;
      }
    );
  };

  const initEventBus = () => {
    EventBus.$on("updateNavbarMessages", loadMessages);
  };

  const destroyEventBus = () => {
    EventBus.$off("updateNavbarMessages", loadMessages);
  };

  return {
    destroyEventBus,
    hasPermissionMessagesView,
    initEventBus,
    loading,
    loadMessages,
    me,
    messagesCount,
    messagesPreview,
  };
}
