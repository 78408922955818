import {
  forEach,
  get, isEmpty,
} from "lodash-es";

import {
  checkPermissionsSync,
} from "../global/compositionAPI/PermissionAPI";
import {
  currentStudierendeInstanz,
} from "../Views/ViewsStudierende/compositionAPi/StudierendeInstanzenAPI";
import {
  currentInstanz,
  isInstanzSelected,
} from "../Views/Instanzen/compositionAPI/InstanzenUniAPI";
import {
  isBR,
  isMinisterium,
  isSchule,
  isSeminar,
  isStudierende,
  isSuperuser,
  isUni,
  isZfsl,
} from "../global/compositionAPI/UserAPI";

export function isRouteVisible({ to }) {
  const PERMISSIONS = get(to, "meta.permissions") || [];
  const IS_STUDIERENDE_PERMISSIONS = get(to, "meta.instanzStudierendePermissions");
  const IS_UNI_INSTANZ_PERMISSIONS = get(to, "meta.instanzPermissions");
  const ROLES = get(to, "meta.roles");
  const EXCLUDE_ROLES = get(to, "meta.excludeRoles");

  let hasPermissions = true;
  let hasStudierendePermissions = true;
  let hasUniInstanzPermissions = true;
  if (PERMISSIONS.length) {
    if (IS_STUDIERENDE_PERMISSIONS) {
      if (isEmpty(currentStudierendeInstanz.value)) {
        hasStudierendePermissions = false;
      } else {
        hasStudierendePermissions = checkPermissionsSync({
          permission: PERMISSIONS,
          permissionList: currentStudierendeInstanz.value.user_permissions,
        });
      }
    } else if (IS_UNI_INSTANZ_PERMISSIONS) {
      if (isInstanzSelected.value) {
        hasUniInstanzPermissions = checkPermissionsSync({
          permission: PERMISSIONS,
          permissionList: currentInstanz.value.user_permissions,
        });
      } else {
        hasUniInstanzPermissions = false;
      }
    } else {
      hasPermissions = checkPermissionsSync({
        permission: PERMISSIONS,
      });
    }
  }
  if (!hasPermissions || !hasStudierendePermissions || !hasUniInstanzPermissions) {
    return false;
  }

  const ROLES_MAPPING = {
    br: isBR.value,
    ministerium: isMinisterium.value,
    schule: isSchule.value,
    seminar: isSeminar.value,
    studierende: isStudierende.value,
    superuser: isSuperuser.value,
    uni: isUni.value,
    zfsl: isZfsl.value,
  };
  if (ROLES) {
    let hasRoles = false;
    forEach(ROLES, role => {
      if (ROLES_MAPPING[role]) {
        hasRoles = true;
        return false;
      }
    });
    if (!hasRoles) {
      return false;
    }
  }

  let hasExcludeRoles = false;
  if (EXCLUDE_ROLES) {
    forEach(EXCLUDE_ROLES, role => {
      if (ROLES_MAPPING[role]) {
        hasExcludeRoles = true;
        return false;
      }
    });
  }
  return !hasExcludeRoles;
}
