import {
  computed,
} from "vue";

import UserAPI from "./UserAPI";

import {
  isString,
  isArray,
  indexOf,
  isUndefined,
} from "lodash-es";

const {
  me,
} = UserAPI();

export const isAuthenticatedSync = computed(() => {
  return !!(me.value && me.value.pk);
});

export default function PermissionAPI() {
  const _checkPermissionsSync = ({ permission, permissionList }) => {
    return checkPermissionsSync({ permission, permissionList });
  };

  const hasOnePermissions = ({ permission = [], permissionList }) => {
    for (let i = 0; i < permission.length; i++) {
      if (_checkSinglePermissions({ perm: permission[i], permArray: permissionList })) {
        return true;
      }
    }
    return false;
  };

  return {
    checkPermissionsSync: _checkPermissionsSync,
    hasOnePermissions,
    isAuthenticatedSync,
    me,
  };
}

export function checkPermissionsSync({ permission, permissionList }) {
  if (permission && !permissionList && !isAuthenticatedSync.value) {
    return false;
  }
  if (isUndefined(permission) || (isArray(permission) && !permission.length)) {
    return true;
  }
  let permArrayLocal = [];
  if (permissionList) {
    permArrayLocal = permissionList;
  } else {
    if (me.value.permissions) {
      permArrayLocal = me.value.permissions;
    }
  }
  return _checkPermissionsWithArray({ perm: permission, permArray: permArrayLocal });
}

function _checkPermissionsWithArray({ perm, permArray }) {
  if (isString(perm)) {
    return _checkSinglePermissions({ perm, permArray });
  } else if (isArray(perm)) {
    return _checkArrayPermissions({ perm, permArray });
  }
  return false;
}

function _checkArrayPermissions({ perm, permArray }) {
  // OR check: Check if user has one of the given rights
  for (let i = 0; i < perm.length; i++) {
    const CURRENT_PERM = perm[i];
    if (permArray.indexOf(CURRENT_PERM) !== -1) {
      return true;
    }
  }
  return false;
}

function _checkSinglePermissions({ perm, permArray }) {
  return isArray(permArray) && indexOf(permArray, perm) !== -1;
}
