import {
  computed,
  toRef,
} from "vue";

import AIcon from "aloha-vue/src/AIcon/AIcon";

import {
  menuDataKeyById,
} from "../../TheMenu/compositionAPI/DataAPI";


// @vue/component
export default {
  name: "TheBreadcrumbItem",
  components: {
    AIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isFirst: {
      type: Boolean,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "openMenuPanel",
  ],
  setup(props, { emit }) {
    const data = toRef(props, "data");
    const isLast = toRef(props, "isLast");
    const index = toRef(props, "index");

    const openMenuPanel = () => {
      emit("openMenuPanel", {
        index: index.value,
      });
    };

    const isMenuButton = computed(() => {
      return !!data.value.menuId;
    });

    const componentLocal = computed(() => {
      if (data.value.to) {
        return "router-link";
      }
      if (isMenuButton.value) {
        return "a";
      }
      return "span";
    });

    const attributesLocal = computed(() => {
      const ATTRIBUTES = {
        style: "vertical-align: middle;",
      };
      if (!isLast.value) {
        ATTRIBUTES.class = "a_btn a_btn_link a_p_0";
        if (isMenuButton.value) {
          ATTRIBUTES.tabindex = "0";
          ATTRIBUTES.role = "button";
          ATTRIBUTES.onClick = openMenuPanel;
        }
      }
      if (data.value.to) {
        ATTRIBUTES.to = data.value.to;
      }
      if (data.value.icon) {
        ATTRIBUTES.style += " line-height: 1;";
      }
      return ATTRIBUTES;
    });

    const labelLocal = computed(() => {
      if (data.value.label) {
        return data.value.label;
      }
      if (data.value.menuId &&
        menuDataKeyById.value[data.value.menuId]) {
        return menuDataKeyById.value[data.value.menuId].label;
      }
      return "";
    });

    const ariaHiddenLabel = computed(() => {
      return `${ isMenuButton.value }`;
    });

    const titleLocal = computed(() => {
      return `Zum Eintrag "${ labelLocal.value }" im Hauptmenü wechseln`;
    });

    return {
      ariaHiddenLabel,
      attributesLocal,
      componentLocal,
      isMenuButton,
      labelLocal,
      titleLocal,
    };
  },
};
