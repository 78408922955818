import {
  computed,
  ref,
} from "vue";
import {
  useRouter,
} from "vue-router";

import {
  getHttp,
} from "aloha-vue/src/compositionAPI/AHttpAPI";
import {
  isStudierende,
} from "../../../global/compositionAPI/UserAPI";
import {
  loadStudierendeInstanzenStatuses,
} from "./StudierendeInstanzenStatusAPI";

import Cookies from "js-cookie";
import {
  keyBy,
} from "lodash-es";

const COOKIES_INSTANZEN_STUDIERENDE_NAME = "instanz_studierende";

export const modelStudierendeInstanzen = ref(undefined);
const dataStudierendeInstanzenKeyByPk = ref({});
const dataStudierendeInstanzen = ref([]);
const isLoadingStudierendeInstanzen = ref(undefined);

export const currentStudierendeInstanz = computed(() => {
  if (modelStudierendeInstanzen.value) {
    return dataStudierendeInstanzenKeyByPk.value[modelStudierendeInstanzen.value] || {};
  }
  return {};
});

export default function StudierendeInstanzenAPI() {
  const $router = useRouter();

  const updateModelStudierendeInstanzen = ({ model }) => {
    modelStudierendeInstanzen.value = model;
    Cookies.set(COOKIES_INSTANZEN_STUDIERENDE_NAME, model);
    loadStudierendeInstanzenStatuses({ isUpdate: true }).then(
      () => {
        $router.push({
          name: "start",
        });
      }
    );
  };

  const removeStudierendeInstanzen = () => {
    dataStudierendeInstanzen.value = [];
    dataStudierendeInstanzenKeyByPk.value = {};
    Cookies.remove(COOKIES_INSTANZEN_STUDIERENDE_NAME);
    modelStudierendeInstanzen.value = undefined;
  };

  return {
    currentStudierendeInstanz,
    dataStudierendeInstanzen,
    dataStudierendeInstanzenKeyByPk,
    isLoadingStudierendeInstanzen,
    modelStudierendeInstanzen,
    removeStudierendeInstanzen,
    updateModelStudierendeInstanzen,
  };
}

export function loadStudierendeInstanzen() {
  if (!isStudierende.value ||
    isLoadingStudierendeInstanzen.value) {
    return;
  }
  if (dataStudierendeInstanzen.value &&
    dataStudierendeInstanzen.value.length) {
    return;
  }
  isLoadingStudierendeInstanzen.value = true;
  return getHttp({
    url: "studentinstanzen/",
    urlParams: {
      ordering: "-semester__prn_beginn",
    },
  }).then(
    response => {
      dataStudierendeInstanzen.value = response;
      dataStudierendeInstanzenKeyByPk.value = keyBy(response, "pk");
      setModelInstanzen();
      isLoadingStudierendeInstanzen.value = false;
    }
  );
}

export function updateStudierendeInstanz() {
  if (!isStudierende.value ||
    !modelStudierendeInstanzen.value) {
    return;
  }
  return getHttp({
    url: `studentinstanzen/${ modelStudierendeInstanzen.value }/`,
  }).then(
    response => {
      dataStudierendeInstanzenKeyByPk.value[response.pk] = response;
    }
  );
}

function setModelInstanzen() {
  const MODEL_INSTANZEN_FROM_COOKIES = Cookies.get(COOKIES_INSTANZEN_STUDIERENDE_NAME);
  if (MODEL_INSTANZEN_FROM_COOKIES && dataStudierendeInstanzenKeyByPk.value[MODEL_INSTANZEN_FROM_COOKIES]) {
    modelStudierendeInstanzen.value = +MODEL_INSTANZEN_FROM_COOKIES;
    return;
  }
  Cookies.remove(COOKIES_INSTANZEN_STUDIERENDE_NAME);

  if (dataStudierendeInstanzen.value.length === 1) {
    modelStudierendeInstanzen.value = dataStudierendeInstanzen.value[0].pk;
  } else if (dataStudierendeInstanzen.value.length > 1) {
    const DATA_FILTERED = dataStudierendeInstanzen.value.filter(item => {
      return item.i_default;
    });
    if (DATA_FILTERED.length === 1) {
      modelStudierendeInstanzen.value = DATA_FILTERED[0].pk;
    } else {
      modelStudierendeInstanzen.value = dataStudierendeInstanzen.value[0].pk;
    }
  }
}
