import {
  computed,
  toRef,
} from "vue";

import AProgress from "aloha-vue/src/AProgress/AProgress";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "PuxTaskLogProgress",
  components: {
    AProgress,
  },
  props: {
    status: {
      type: String,
      required: false,
      default: undefined,
      info: "Status(PENDING, RUNNING, STARTED, SUCCESS, PARTIAL_SUCCESS, FAILURE)",
    },
    progress: {
      type: [String, Number],
      required: false,
      default: undefined,
      info: "Progress",
    },
  },
  setup(props) {
    const {
      filterCurrency,
    } = AFiltersAPI();

    const progress = toRef(props, "progress");
    const value = computed(() => {
      return progress.value || 0;
    });

    const valueFiltered = computed(() => {
      return filterCurrency(value.value, { suffix: "%", digits: 1 });
    });

    const status = toRef(props, "status");
    const classLocal = computed(() => {
      return {
        a_bg_info: status.value === "PENDING" || status.value === "RUNNING" || status.value === "STARTED",
        a_bg_success: status.value === "SUCCESS",
        a_bg_warning: status.value === "PARTIAL_SUCCESS",
        a_bg_danger: status.value === "FAILURE",
      };
    });

    return {
      classLocal,
      filterCurrency,
      value,
      valueFiltered,
    };
  },
};
