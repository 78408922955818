import {
  onBeforeUnmount,
} from "vue";

import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import AIcon from "aloha-vue/src/AIcon/AIcon";
import ALink from "aloha-vue/src/ALink/ALink";
import TheNavbarNewsItem from "./TheNavbarNewsItem/TheNavbarNewsItem.vue";

import DataAPI from "./compositionAPI/DataAPI";

// @vue/component
export default {
  name: "TheNavbarNews",
  components: {
    ADropdown,
    AIcon,
    ALink,
    TheNavbarNewsItem,
  },
  setup() {
    const {
      destroyEventBus,
      initEventBus,
      loading,
      loadNews,
      newsCount,
      newsPreview,
    } = DataAPI();

    loadNews();
    initEventBus();

    onBeforeUnmount(() => {
      destroyEventBus();
    });

    return {
      loading,
      newsCount,
      newsPreview,
    };
  },
};
