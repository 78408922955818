const EREIGNIS_HISTORIE_LIST = () => import(/* webpackChunkName: "ereignisHistorieList" */ "../../Views/ViewsStudierende/EreignisHistorieList/EreignisHistorieList.vue");
const STUDIERENDE_AUSWAHL_FINALISIEREN = () => import(/* webpackChunkName: "studierendeAuswahlFinalisieren" */ "../../Views/ViewsStudierende/StudierendeAuswahlFinalisieren/StudierendeAuswahlFinalisieren.vue");
const STUDIERENDE_BESUCHTE_SCHULEN = () => import(/* webpackChunkName: "studierendeBesuchteSchulen" */ "../../Views/ViewsStudierende/StudierendeBesuchteSchulen/StudierendeBesuchteSchulen.vue");
const STUDIERENDE_FAVORITEN = () => import(/* webpackChunkName: "studierendeFavoriten" */ "../../Views/ViewsStudierende/StudierendeFavoriten/StudierendeFavoriten.vue");
const STUDIERENDE_FAVORITEN_SCHULE_DETAILS = () => import(/* webpackChunkName: "studierendeFavoritenSchuleDetails" */ "../../Views/ViewsStudierende/StudierendeFavoritenSchuleDetails/StudierendeFavoritenSchuleDetails.vue");
const STUDIERENDE_MERKMALE = () => import(/* webpackChunkName: "studierendeMerkmale" */ "../../Views/ViewsStudierende/StudierendeMerkmale/StudierendeMerkmale.vue");
const STUDIERENDE_MERKMALE_SCHULE_DETAILS = () => import(/* webpackChunkName: "studierendeMerkmaleSchuleDetails" */ "../../Views/ViewsStudierende/StudierendeMerkmaleSchuleDetails/StudierendeMerkmaleSchuleDetails.vue");
const STUDIERENDE_ORTSPUNKT = () => import(/* webpackChunkName: "studierendeOrtspunkt" */ "../../Views/ViewsStudierende/StudierendeOrtspunkt/StudierendeOrtspunkt.vue");
const STUDIERENDE_PERSOENLICHE_DATEN = () => import(/* webpackChunkName: "studierendePersoenlicheDaten" */ "../../Views/ViewsStudierende/StudierendePersoenlicheDaten/StudierendePersoenlicheDaten.vue");
const STUDIERENDE_PRAKTIKUMSDATEN = () => import(/* webpackChunkName: "studierendePraktikumsdaten" */ "../../Views/ViewsStudierende/StudierendePraktikumsdaten/StudierendePraktikumsdaten.vue");
const STUDIERENDE_PROFILDATEN = () => import(/* webpackChunkName: "studierendeProfildaten" */ "../../Views/ViewsStudierende/StudierendeProfildaten/StudierendeProfildaten.vue");
const STUDIERENDE_STUDIENDATEN = () => import(/* webpackChunkName: "studierendeStudiendaten" */ "../../Views/ViewsStudierende/StudierendeStudiendaten/StudierendeStudiendaten.vue");
const STUDIERENDE_PRAKTIKUM_ANMELDEN = () => import(/* webpackChunkName: "studierendePraktikumAnmelden" */ "../../Views/ViewsStudierende/StudierendePraktikumAnmelden/StudierendePraktikumAnmelden.vue");
const STUDIERENDE_WUNSCHLISTE = () => import(/* webpackChunkName: "studierendeWunschliste" */ "../../Views/ViewsStudierende/StudierendeWunschliste/StudierendeWunschliste.vue");
const STUDIERENDE_WUNSCHLISTE_SCHULE_DETAILS = () => import(/* webpackChunkName: "studierendeWunschlisteSchuleDetails" */ "../../Views/ViewsStudierende/StudierendeWunschlisteSchuleDetails/StudierendeWunschlisteSchuleDetails.vue");
const STUDIERENDE_ZUWEISUNG = () => import(/* webpackChunkName: "studierendeZuweisung" */ "../../Views/ViewsStudierende/StudierendeZuweisung/StudierendeZuweisung.vue");
const STUDIERENDE_ZUWEISUNG_SCHULE_DETAILS = () => import(/* webpackChunkName: "studierendeZuweisungSchuleDetails" */ "../../Views/ViewsStudierende/StudierendeZuweisungSchuleDetails/StudierendeZuweisungSchuleDetails.vue");

export default [
  {
    path: "/studierende/anmeldung/",
    name: "studierendePraktikumAnmelden",
    component: STUDIERENDE_PRAKTIKUM_ANMELDEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.registrationform_view",
      ],
    },
  },
  {
    path: "/studierende/auswahl-finalisieren/",
    name: "studierendeAuswahlFinalisieren",
    component: STUDIERENDE_AUSWAHL_FINALISIEREN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.wishlist_view",
      ],
    },
  },
  {
    path: "/studierende/besuchte-schulen/",
    name: "studierendeBesuchteSchulen",
    component: STUDIERENDE_BESUCHTE_SCHULEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.visited_schools_view",
      ],
    },
  },
  {
    path: "/studierende/favoriten/",
    name: "studierendeFavoriten",
    component: STUDIERENDE_FAVORITEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.wishlist_view",
      ],
    },
  },
  {
    path: "/studierende/favoriten/:id/",
    name: "studierendeFavoritenSchuleDetails",
    component: STUDIERENDE_FAVORITEN_SCHULE_DETAILS,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.wishlist_view",
      ],
    },
  },
  {
    path: "/studierende/merkmale/",
    name: "studierendeMerkmale",
    component: STUDIERENDE_MERKMALE,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.schooltraits_view",
      ],
    },
  },
  {
    path: "/studierende/merkmale/:id/",
    name: "studierendeMerkmaleSchuleDetails",
    component: STUDIERENDE_MERKMALE_SCHULE_DETAILS,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.schooltraits_view",
      ],
    },
  },
  {
    path: "/studierende/ortspunkt/",
    name: "studierendeOrtspunkt",
    component: STUDIERENDE_ORTSPUNKT,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.location_view",
      ],
    },
  },
  {
    path: "/studierende/persoenliche-daten/",
    name: "studierendePersoenlicheDaten",
    component: STUDIERENDE_PERSOENLICHE_DATEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.profile_view",
      ],
    },
  },
  {
    path: "/studierende/praktikumsdaten/",
    name: "studierendePraktikumsdaten",
    component: STUDIERENDE_PRAKTIKUMSDATEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.participation_view",
      ],
    },
  },
  {
    path: "/studierende/profildaten/",
    name: "studierendeProfildaten",
    component: STUDIERENDE_PROFILDATEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.login",
      ],
    },
  },
  {
    path: "/studierende/studiendaten/",
    name: "studierendeStudiendaten",
    component: STUDIERENDE_STUDIENDATEN,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.education_data_view",
      ],
    },
  },
  {
    path: "/studierende/wunschliste/",
    name: "studierendeWunschliste",
    component: STUDIERENDE_WUNSCHLISTE,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.wishlist_view",
      ],
    },
  },
  {
    path: "/studierende/wunschliste/:id/",
    name: "studierendeWunschlisteSchuleDetails",
    component: STUDIERENDE_WUNSCHLISTE_SCHULE_DETAILS,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.wishlist_view",
      ],
    },
  },
  {
    path: "/studierende/zuweisung/",
    name: "studierendeZuweisung",
    component: STUDIERENDE_ZUWEISUNG,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.assignments_view",
      ],
    },
  },
  {
    path: "/studierende/zuweisung/:id/",
    name: "studierendeZuweisungSchuleDetails",
    component: STUDIERENDE_ZUWEISUNG_SCHULE_DETAILS,
    meta: {
      instanzStudierendePermissions: true,
      permissions: [
        "student.assignments_view",
      ],
    },
  },
  {
    path: "/info/ereignisse/",
    name: "infoEreignisseList",
    component: EREIGNIS_HISTORIE_LIST,
    meta: {
      permissions: [
        "student.login",
      ],
    },
  },
];
