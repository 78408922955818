const DatenschutzPage = () => import(/* webpackChunkName: "datenschutz" */ "../../Views/StaticPages/DatenschutzPage/DatenschutzPage.vue");
const DOWNLOADS_LIST = () => import("../../Views/Downloads/DownloadsList/DownloadsList.vue");
const ImpressumPage = () => import(/* webpackChunkName: "impressum" */ "../../Views/StaticPages/ImpressumPage/ImpressumPage.vue");
const START_PAGE = () => import(/* webpackChunkName: "start" */ "../../Views/StartPage/StartPage.vue");
const WartungPage = () => import(/* webpackChunkName: "wartungPage" */ "../../Views/Registration/WartungPage/WartungPage.vue");


export default [
  {
    path: "/",
    name: "start",
    component: START_PAGE,
    meta: {
      title: "Startseite",
    },
  },
  {
    path: "/verwaltung/",
    name: "verwaltung",
    component: START_PAGE,
  },
  {
    path: "/starttest/",
    name: "startTest",
    component: START_PAGE,
  },
  {
    path: "/datenschutz/",
    name: "datenschutz",
    component: DatenschutzPage,
    meta: {
      loginNoMatter: true,
    },
  },
  {
    path: "/impressum/",
    name: "impressum",
    component: ImpressumPage,
    meta: {
      loginNoMatter: true,
    },
  },
  {
    path: "/wartung/",
    name: "wartungPage",
    component: WartungPage,
    meta: {
      loginNoMatter: true,
    },
  },
  {
    path: "/downloads/",
    name: "downloadsList",
    component: DOWNLOADS_LIST,
    meta: {
      permissions: [
        "downloads.view",
      ],
    },
  },
];
