import {
  ref,
} from "vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";
import LoginAPI from "../../../LoginPage/LoginSnippet/compositionAPI/LoginAPI";

import {
  get,
} from "lodash-es";

export default function SelectAPI(props, { emit }) {
  const errors = ref({});
  const saving = ref(false);

  const {
    postHttp,
  } = AHttpAPI();

  const {
    addNotification,
  } = ANotificationAPI();

  const {
    onAfterLogin,
  } = LoginAPI({}, {});

  const closeLocal = () => {
    emit("close");
  };

  const deleteErrors = () => {
    errors.value = {};
  };

  const addSaveNotificationSuccess = ({ user }) => {
    addNotification({
      text: `Sie arbeiten jetzt in der Rolle für: <strong>${ get(user, "organisation_obj.name") }</strong>`,
    });
  };

  const selectUser = ({ user }) => {
    deleteErrors();
    saving.value = true;
    return postHttp({
      url: "profil/switch_user/",
      data: {
        nutzer: user.pk,
      },
    }).then(
      () => {
        addSaveNotificationSuccess({ user });
        closeLocal();
        onAfterLogin({ isKontextwechsel: true });
      },
      error => {
        errors.value = error.data;
      },
    ).finally(
      () => {
        saving.value = false;
      },
    );
  };

  return {
    closeLocal,
    errors,
    saving,
    selectUser,
  };
}
